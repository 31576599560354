import {WebsiteFooter, WebsiteHeader} from "../layout";


import {
    LandingPage_BuyCryptoSection,
    LandingPage_ConnectWalletSection,
    LandingPage_MainSection,
    LandingPage_PriceSection,
    LandingPage_VideoSection,
    LandingPage_MetaverseCountDownSection
} from "./index";
import {LandingPage_Meta2} from "./meta2/landingPage_Meta2";
import {Test} from "./test/Test.jsx";



export function LandingPage() {


    return (
        <div className="App">
            <WebsiteHeader/>

            <LandingPage_MainSection/>
            {/*<LandingPage_VideoSection/>*/}
            <LandingPage_Meta2/>
            {/*<Test/>*/}
            <LandingPage_PriceSection/>
            <LandingPage_ConnectWalletSection/>
            <LandingPage_BuyCryptoSection/>
            <LandingPage_MetaverseCountDownSection/>
            <WebsiteFooter/>
        </div>

    );
}


