import * as React from 'react';
import {LoadingButton} from '@mui/lab'
import './style.scss'
import {
    ConnectWalletHelper,
    ConnectWallet_TransactionButton,
    PolicyConfirmationDialog
} from "../../../../core/components";
import {AdminAuthHelper, ToastHelper} from "../../../../core/helper";
import {AdminPackageRepo} from "../../../../core/repository/admin";
import {AdminPanel_ActionBar} from "../components";


export function AdminPackageSelectorPage() {
    const [openPolicyConfirmationDialog, setPolicyConfirmationDialog] = React.useState(false);
    const adminModel = AdminAuthHelper.getAdmin();
    const [state, setState] = React.useState({
        loading: false,
        selectedPackageId: 0
    })
    const packageSelected = (packageId) => {

        let financeBalance = Number(adminModel.financialBalance);
        switch (packageId) {
            case 1: {
                if (financeBalance >= 20000000000000000000000) {
                    state.selectedPackageId = packageId;

                }
                break;
            }
            case 2: {
                if (financeBalance >= 40000000000000000000000) {
                    state.selectedPackageId = packageId;

                }
                break;
            }
            case 3: {
                if (financeBalance >= 80000000000000000000000) {
                    state.selectedPackageId = packageId;

                }
                break;
            }
            case 4: {
                if (financeBalance >= 200000000000000000000000) {
                    state.selectedPackageId = packageId;

                }
                break;
            }
        }
        if (state.selectedPackageId > 0) {
            state.loading = true;
            setState({...state, state});
            setPolicyConfirmationDialog(true)
        } else {
            ToastHelper.showError('Your account balance is not enough to choose this package.\n' +
                'Please increase your TLT credit balance through the panel.', 'Insufficient Credit')
        }

    }

    return (
        <>
            <div className="AdminPanel_ContentBase">
                <AdminPanel_ActionBar>
                    <div className="side">

                    </div>
                    <div className="side">
                        <ConnectWallet_TransactionButton/>
                    </div>
                </AdminPanel_ActionBar>

                <div className="frame">

                    <div className="title">
                        <div className="card">
                            <div className="card-header">
                                <h4>
                                    Administrators initialization Plans
                                </h4>
                                <p>
                                    You have 1 month to reach this goal. Otherwise, your admin location will be restored
                                    and
                                    90% TLT of your purchase will be refunded. For those who have entered the management
                                    system for the first time (new admins), there is a 1-month time frame for the
                                    selected
                                    goal in the form of tolerance.
                                </p>

                            </div>
                        </div>
                    </div>

                    {openPolicyConfirmationDialog ? <PolicyConfirmationDialog onClose={(res) => {
                        setPolicyConfirmationDialog(false)
                        if (res) {
                            AdminPackageRepo.select({
                                packageId: state.selectedPackageId
                            }).then((res) => {
                                try {
                                    if (res.data.admin) {
                                        AdminAuthHelper.setAdmin(res.data.admin)
                                        window.reload();
                                    }
                                } catch (e) {

                                }
                                state.selectedPackageId = 0;
                                state.loading = false;
                                setState({...state, state})
                            }).catch((err) => {
                                ToastHelper.showError();
                                state.selectedPackageId = 0;
                                state.loading = false;
                                setState({...state, state})
                            })
                        }
                    }}/> : ''}

                    <div className="planes-list">
                        <div className={adminModel.currentPackageId === 1 ? 'card active' : 'card'}>
                            <div className="card-header">
                                <h4>
                                    Actinium Plan
                                </h4>
                                <p></p>
                            </div>
                            <div className="card-content">
                                <ul>
                                    <li>
                                        Community: 200 (
                                        <strong className='yellow-color'>
                                            monthly
                                        </strong>
                                        )
                                    </li>
                                    <li>
                                        Duration: 1 Year
                                    </li>
                                    <li>Cost:
                                        <span style={{textDecoration: 'line-through'}}>
                                        25,000
                                    </span>
                                        {' '}20,000
                                        <strong className='yellow-color'>
                                            TLT
                                        </strong>


                                    </li>
                                    <li>
                                        Commission: 1%
                                        (
                                        <strong className='yellow-color'>
                                            None level
                                        </strong>
                                        )
                                    </li>
                                </ul>
                            </div>
                            <div className="card-footer">
                                <LoadingButton
                                    disabled={state.loading || adminModel.currentPackageId > 0}
                                    loading={state.loading && state.selectedPackageId === 1}
                                    loadingPosition="center"
                                    variant="contained" className="white-btn"
                                    onClick={() => {
                                        packageSelected(1)
                                    }}>
                                    Select
                                </LoadingButton>
                            </div>
                        </div>

                        <div className={adminModel.currentPackageId === 2 ? 'card active' : 'card'}>
                            <div className="card-header">
                                <h4>
                                    Protactinium Plan
                                </h4>
                                <p></p>
                            </div>
                            <div className="card-content">
                                <ul>
                                    <li>
                                        Community: 250 (
                                        <strong className='yellow-color'>
                                            monthly
                                        </strong>
                                        )
                                    </li>
                                    <li>
                                        Duration: 1 Year
                                    </li>
                                    <li>Cost:
                                        <span style={{textDecoration: 'line-through'}}>
                                        50,000
                                    </span>
                                        {' '}40,000
                                        <strong className='yellow-color'>
                                            TLT
                                        </strong>


                                    </li>
                                    <li>
                                        Commission: 1.5%
                                        (
                                        <strong className='yellow-color'>
                                            None level
                                        </strong>
                                        )
                                    </li>
                                </ul>
                            </div>
                            <div className="card-footer">
                                <LoadingButton
                                    disabled={state.loading || adminModel.currentPackageId > 0}
                                    variant="contained" className="white-btn"
                                    loading={state.loading && state.selectedPackageId === 2}
                                    loadingPosition="center"
                                    onClick={() => {
                                        packageSelected(2)
                                    }}>
                                    Select
                                </LoadingButton>
                            </div>
                        </div>
                        <div className={adminModel.currentPackageId === 3 ? 'card active' : 'card'}>
                            <div className="card-header">
                                <h4>
                                    Neptunium Plan
                                </h4>
                                <p></p>
                            </div>
                            <div className="card-content">
                                <ul>
                                    <li>
                                        Community: 300 (
                                        <strong className='yellow-color'>
                                            monthly
                                        </strong>
                                        )
                                    </li>
                                    <li>
                                        Duration: 1 Year
                                    </li>
                                    <li>Cost:
                                        <span style={{textDecoration: 'line-through'}}>
                                        100,000
                                    </span>
                                        {' '}80,000
                                        <strong className='yellow-color'>
                                            TLT
                                        </strong>


                                    </li>
                                    <li>
                                        Commission: 2.5%
                                        (
                                        <strong className='yellow-color'>
                                            None level
                                        </strong>
                                        )
                                    </li>
                                </ul>
                            </div>
                            <div className="card-footer">
                                <LoadingButton
                                    disabled={state.loading || adminModel.currentPackageId > 0}
                                    variant="contained" className="white-btn"
                                    loading={state.loading && state.selectedPackageId === 3}
                                    loadingPosition="center"
                                    onClick={() => {
                                        packageSelected(3)
                                    }}>
                                    Select
                                </LoadingButton>
                            </div>
                        </div>
                        <div className={adminModel.currentPackageId === 4 ? 'card active' : 'card'}>
                            <div className="card-header">
                                <h4>
                                    Plutonium Plan
                                </h4>
                                <p></p>
                            </div>
                            <div className="card-content">
                                <ul>
                                    <li>
                                        Community: 350 (
                                        <strong className='yellow-color'>
                                            monthly
                                        </strong>
                                        )
                                    </li>
                                    <li>
                                        Duration: 1 Year
                                    </li>
                                    <li>Cost:
                                        <span style={{textDecoration: 'line-through'}}>
                                        250,000
                                    </span>
                                        {' '}200,000
                                        <strong className='yellow-color'>
                                            TLT
                                        </strong>


                                    </li>
                                    <li>
                                        Commission: 4%
                                        (
                                        <strong className='yellow-color'>
                                            None level
                                        </strong>
                                        )
                                    </li>
                                </ul>
                            </div>
                            <div className="card-footer">
                                <LoadingButton
                                    disabled={state.loading || adminModel.currentPackageId > 0}
                                    loading={state.loading && state.selectedPackageId === 4}
                                    loadingPosition="center"
                                    variant="contained" className="primary-btn"
                                    onClick={() => {
                                        packageSelected(4)
                                    }}>
                                    Select
                                </LoadingButton>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </>
    );
}

