import './style.scss';
import * as React from "react";

export function AdminPanel_ActionBar(props) {
    const [state, setState] = React.useState({
        loading: false
    })

    return (
        <div className='AdminPanel_ActionBar'>
            {props.children}
        </div>

    )
}
