import React, {useEffect, useState} from 'react'
import './walletSelectorDialog.scss'

import Dialog from "@mui/material/Dialog";
import {Icon, IconEnum, IconSizeEnum} from "../..";
import PropTypes from 'prop-types';

const WalletEnum = {
    Metamask: 'Metamask',
    TrustWallet: 'TrustWallet',
    WalletConnect: 'WalletConnect',
}

export function WalletSelectorDialog(props) {
    const [state, setState] = useState(window.wallet)

    useEffect((e) => {
        window.wallet.observable.subscribe((res) => {
            setState({...state, state})
        })
    }, [])


    const walletSelected = (wallet: WalletEnum) => {
        switch (wallet) {
            case WalletEnum.Metamask: {
                window.open('https://metamask.app.link/trumpland.io', '_blank')
                break;
            }
            case WalletEnum.TrustWallet: {
                window.open('https://link.trustwallet.com/open_url?url=https://trumpland.io/', '_blank')
                break;
            }
            case WalletEnum.WalletConnect: {
                window.wallet.open();
                break;
            }
        }
    }

    return (
        <>


            <Dialog open={props.open} className="WalletSelectorDialog">
                <div className='dialog-content'>
                    <h1>
                        Connect Wallet
                    </h1>
                    <p>
                        Start by connecting with one of the wallets below. Be sure to store your private keys or seed
                        phrase securely. Never share them with anyone.
                    </p>
                    <div className="item-list">
                        <div
                            onClick={() => {
                                walletSelected(WalletEnum.Metamask)
                            }}>
                            <Icon name={IconEnum.walletIcon.metaMask} size={IconSizeEnum.l}/>
                            Metamask
                        </div>
                        <div
                            onClick={() => {
                                walletSelected(WalletEnum.TrustWallet)
                            }}>
                            <Icon name={IconEnum.walletIcon.trustWallet} size={IconSizeEnum.l}/>
                            Trust Wallet
                        </div>
                        <div
                            onClick={() => {
                                walletSelected(WalletEnum.WalletConnect)
                            }}>
                            <Icon name={IconEnum.walletIcon.connectWallet} size={IconSizeEnum.l}/>
                            WalletConnect
                        </div>
                    </div>

                </div>
            </Dialog>

        </>

    );
}


WalletSelectorDialog.propTypes = {
    open: PropTypes.bool,
}
