import {StorageHelper} from "./storageHelper";
import {AdminModel} from "../model";

export class AdminAuthHelper {


    static setAdmin(admin) {
        StorageHelper.saveTemporary('admin', admin);
    }

    static setToken(token) {
        StorageHelper.saveTemporary('token', token);
    }

    static getAdmin() {
        let pureData = StorageHelper.loadTemplate('admin');
        if (pureData) {
            let model = new AdminModel();
            model.adminId = Number(pureData.adminId || 0);
            model.rule = pureData.rule || '';
            model.email = pureData.email || '';
            model.firstName = pureData.firstName || '';
            model.fullName = pureData.fullName || '';
            model.gender = pureData.gender || '';
            model.isActive = Boolean(Number(pureData.isActive || 0));
            model.label = pureData.label || '';
            model.lastName = pureData.lastName || '';
            model.level = pureData.level || '';
            model.username = pureData.username || '';
            model.uuid = pureData.uuid || '';
            model.currentPackageId = Number(pureData.currentPackageId) || 0;
            model.financialBalance = pureData.financialBalance || 0;
            model.birthday = pureData.birthday || '';
            model.country = pureData.country || '';
            model.city = pureData.city || '';
            model.editLock = Boolean(Number(pureData.editLock || 0));
            model.accessAdminManager = Boolean(Number(pureData.accessAdminManager || 0));
            model.forceUpdate = Boolean(Number(pureData.forceUpdate || 0));

            return model;
        } else {
            return new AdminModel();
        }
    }

    static getToken() {
        return StorageHelper.loadTemplate('token') || '';
    }

    static removeAll() {
        StorageHelper.removeTemporary('admin');
        StorageHelper.removeTemporary('token');
    }


}
