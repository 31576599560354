import React, {useEffect, useState} from 'react'
import {LoadingButton} from '@mui/lab'
import {WalletSelectorDialog} from "./walletSelectorDialog";
import {
    isMobile,
    isTablet,
    isMobileSafari,
    isAndroid,
    isIOS,
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isIE,
    isEdge
} from "react-device-detect";

export function WalletConnectButton() {
    const [state, setState] = useState(window.wallet)
    const [openWalletSelector, setOpenWalletSelector] = useState(false)

    /*useEffect((e) => {
        window.wallet.observable.subscribe((res) => {
            setState({...state, state})
        })
    }, [])*/

    return (
        <>
            <div className='ConnectButton'>
                <LoadingButton
                    disabled={window.wallet.loading}
                    loading={window.wallet.loading}
                    loadingPosition='center'
                    variant="contained"
                    className="primary-btn"
                    onClick={() => {
                        if (isMobile || isTablet || isIOS || isAndroid) {
                            if (isSafari || isChrome || isMobileSafari || isFirefox || isOpera || isIE || isEdge) {
                                setOpenWalletSelector(true);
                            } else {
                                window.wallet.open();
                            }
                        } else {
                            window.wallet.open();
                        }
                    }}>
                    Connect Wallet
                </LoadingButton>

                <WalletSelectorDialog open={openWalletSelector}/>
            </div>
        </>

    );
}


