import "./style.scss";
import React from "react";
import {Link, useNavigate, Navigate, Outlet, useLocation} from "react-router-dom";
import {AdminAuthHelper} from "../../../../core/helper";


import {AdminSideMenu} from "./sideMenu/AdminSideMenu";
import {Routes} from "../../../../core/router";
import {ConnectWalletComponent} from "../../../../core/components";

export function AdminLayout() {
    const [reload, setReload] = React.useState(false);
    window.reload = () => {
        setReload(!reload)
    };
    const navigate = useNavigate();
    let location = useLocation()
    React.useEffect(() => {
        let admin = AdminAuthHelper.getAdmin()
        let checkNext = true;
        if (admin.forceUpdate) {
            if (location.pathname !== Routes.admin.profileInfo) {
                navigate(Routes.admin.profileInfo)
            }
            checkNext = false;
        }

        if (checkNext) {
            if (!admin.currentPackageId && location.pathname !== Routes.admin.adminPackageSelector) {
                navigate(Routes.admin.adminPackageSelector)
            }
        }


    }, [location])

    const checkAdminToken = () => {
        return AdminAuthHelper.getToken() || false;
    };

    return (
        <>
            {
                checkAdminToken() ?

                    <>
                        <div className="AdminPanel_Layout">

                            <AdminSideMenu/>
                            <div className='right-side'>
                                <div className='header'>
                                    <div className="side">

                                    </div>
                                    <div className="side">
                                        <ConnectWalletComponent/>
                                    </div>
                                </div>

                                <div className='content'>
                                    <Outlet/>
                                </div>
                            </div>
                        </div>

                    </>
                    :
                    <Navigate to={Routes.admin.login} replace/>
            }
        </>
    )
}
