import React, {useEffect, useState} from 'react'
import './style.scss';
import {LoadingButton} from '@mui/lab'
import {WalletConnectButton, WalletHelper} from "../..";



export function WalletTransactionButton() {
    const [state, setState] = useState(window.wallet)

    useEffect((e) => {
        window.wallet.observable.subscribe((res) => {
            setState({...state, state})
        })
    }, [])


    return (() => {
        if (state.isConnected) {

            return <> <LoadingButton
                disabled={window.wallet.loading}
                loading={window.wallet.loading}
                loadingPosition='center'
                variant="contained"
                className="primary-btn"
                onClick={() => {
                    WalletHelper.Transaction_getBNB();
                }}
            >
                Buy TLT
            </LoadingButton>

                </>
        } else {

            return <WalletConnectButton/>

        }
    })()

}


