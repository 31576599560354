import Image from 'assets/img/metaverse_01.jpg'
import './style.scss'
import {Routes} from "../../../core/router";
import {Link} from "react-router-dom";
import React from "react";

export function LandingPage_MetaverseCountDownSection() {

    setTimeout(() => {
        try {
            let area = document.querySelector('w3m-connect-button').shadowRoot.querySelector('wui-connect-button').shadowRoot
            let btn = area.querySelector('button')
            btn.style.setProperty('--wui-color-accent-100', '#FFAA00')
            btn.style.setProperty('--wui-color-accent-090', '#FFAA00')
            btn.style.setProperty('--wui-color-accent-080', '#FFAA00')
            btn.style.setProperty('--wui-border-radius-m', '4px')
            btn.style.setProperty('width', '100%')
            btn.style.setProperty('height', '55px')

            let text = area.querySelector('wui-text').shadowRoot.querySelector('slot')
            text.style.setProperty('font-family', 'Roboto')
            text.style.setProperty('font-size', '20px')
        } catch (e) {

        }
    }, 100)
    setTimeout(() => {

        /*
        try {
            let btnElement = document.querySelector('.LandingPage_ConnectWallet .btn').childNodes[0].shadowRoot.childNodes[2].shadowRoot.childNodes[2];
            btnElement.style.width = '100%'
            btnElement.style.height = '55px'
            btnElement.style.borderRadius = '3px'
        }catch (e) {

        }

        try {
            let txtElement = document.querySelector('.LandingPage_ConnectWallet .btn').childNodes[0].shadowRoot.childNodes[2].shadowRoot.childNodes[2].childNodes[3].shadowRoot.childNodes[1];
            txtElement.style.fontFamily = 'Roboto'
            txtElement.style.fontSize = '20px'

        }catch (e) {

        }*/
    }, 500)
    return (
        <section className='LandingPage_MetaverseCountDownSection'>
            <div className="master-content-frame">
                <div className="frame">
                    <div className="left">
                        <img src={Image}/>

                    </div>
                    <div className="right">
                        <h2>
                            Trumpland Metaverse World
                        </h2>

                        <p>
                            We're ringing in the New Year with the biggest usability event in the Metaverse. Until then,
                            try to increase your TLT reserves to take advantage of the Aureus Metaverse country.
                        </p>
                        <p>
                            Experience the most profitable Metaverse project of the century.
                        </p>
                        <p>
                            Get ready to enter the wonderful world of Metaverse in
                            <br/>
                            <strong className='primary-color'>
                                {' '} February 2024
                            </strong>
                        </p>
                        <div className="btn">
                            <Link to={Routes.website.metaverse}>
                                Read More ...
                            </Link>
                        </div>
                        {/*<Button variant="contained" className="primary-btn contained">
                            Connect Wallet
                        </Button>*/}
                    </div>
                </div>

            </div>
        </section>
    )
}
