import "./style.scss";
import React from "react";
import {Button} from "@mui/material"
import {useNavigate} from "react-router-dom"
import {Outlet} from "react-router-dom";
import {AdminPanel_ActionBar} from "../../admin/components";
import {Routes} from "../../../../core/router";

export function SystemManagerLayout() {

    const navigator = useNavigate();

    return (
        <>
            <div className="inner-content">
                <AdminPanel_ActionBar>
                    <div className="side">
                        <Button variant='contained' className="white-btn"
                                onClick={() => {
                                    navigator(Routes.systemManager.request.list)
                                }}>
                            Request List
                        </Button>
                    </div>
                    <div className="side">
                        <Button variant='contained' className="white-btn"
                                onClick={() => {
                                    navigator(Routes.systemManager.checkTxh)
                                }}>
                            Check Txh
                        </Button>
                    </div>
                </AdminPanel_ActionBar>
                <Outlet/>
            </div>

        </>
    )
}
