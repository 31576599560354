export class ValidationHelper {

    static checkTxhValidation(txh) {
        return txh.length !== 66
    }

    static checkReferralCodeValidation(referralCode, mandatory: boolean = true)
        : {
        validate: boolean,
        errorMessage: string
    } {
        if (mandatory && referralCode.length === 0) {
            return {
                validate: false,
                errorMessage: 'The referral code is required'
            }
        }
        if (
            referralCode.length === 0 ||
            referralCode.length === 9 ||
            referralCode.length === 36
        ) {
            return {
                validate: true,
                errorMessage: ''
            }
        }
        return {
            validate: false,
            errorMessage: 'The referral code is wrong'
        }
    }

    static checkEmailValidation(email, mandatory: boolean = true)
        : {
        validate: boolean,
        errorMessage: string
    } {
        if (mandatory && email.length === 0) {
            return {
                validate: false,
                errorMessage: 'The email address is required'
            }
        }


        let regex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
        if (regex.test(email)) {
            return {
                validate: true,
                errorMessage: ''
            }
        }
        return {
            validate: false,
            errorMessage: 'The email address is probably not correct.'
        }
    }
}
