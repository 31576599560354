import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";


import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    Navigate
} from "react-router-dom";
import WhitePaperController from "./pages/whitePaper/controller";

import {
    AboutUsPage,
    ConfirmRegistrationPage,
    ContactUsPage,
    LandingPage,
    MetaversePage,
    Page404,
    TestPage
} from "./pages";

import {
    AdminDashboardPage,
    AdminLayout,
    AdminLoginPage,
    AdminPackageSelectorPage,
    AdminProfileInfoPage,
    AdminTransactionHistoryPage
} from "./pages/panels/admin";

import {AxiosInspector} from "./core/repository";

import {
    SystemManage_CheckTxh_Page,
    SystemManage_ConfirmTxh_Page,
    SystemManage_RequestList_Page,
    SystemManagerLayout
} from "./pages/panels/systemManager";
import {WalletHelper} from "./core/helper";


AxiosInspector();


const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        children: [
            {
                path: "/",
                element: <LandingPage/>,
            },
            {
                path: "/about-us",
                element: <AboutUsPage/>,
            },
            {
                path: "/contact-us",
                element: <ContactUsPage/>,
            },
            {
                path: "/metaverse",
                element: <MetaversePage/>,
            },
            {
                path: "/white-paper",
                element: <WhitePaperController/>,
            },
            {
                path: "/confirm-registration/:fallowCode",
                element: <ConfirmRegistrationPage/>,
            },
            {
                path: "/test",
                element: <TestPage/>,
            },
            {
                path: "/404",
                element: <Page404/>,
            },

        ]
    },
    {
        path: '/admin/login',
        element: <AdminLoginPage/>,
    },
    {
        path: '/admin',
        element: <AdminLayout/>,
        children: [
            {
                path: "",
                element: <AdminDashboardPage/>
            }, {
                path: "profile-info",
                element: <AdminProfileInfoPage/>
            }, {
                path: "admin-package-selector",
                element: <AdminPackageSelectorPage/>
            }, {
                path: "transaction-history",
                element: <AdminTransactionHistoryPage/>
            }, {
                path: "system-manager",
                element: <SystemManagerLayout/>,
                children: [
                    {
                        path: "check-txh",
                        element: <SystemManage_CheckTxh_Page/>
                    },
                    {
                        path: "request",
                        element: <SystemManage_RequestList_Page/>
                    },
                    {
                        path: "confirm-txh/:txh",
                        element: <SystemManage_ConfirmTxh_Page/>,
                    },
                ]
            }
        ]
    },

    {
        path: "*",
        element: <Page404/>,
    }

    /* {
         path: "/aboutUs",
         element: <App/>,
     },*/
]);


ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router}/>
    /*<React.StrictMode>
    </React.StrictMode>*/
);

/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*reportWebVitals();*/
