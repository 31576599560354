import * as React from 'react';
import './style.scss'
import {StandardHelper, ToastHelper} from "../../../../core/helper";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {SystemManager_BuyRequestRepo} from "../../../../core/repository/systemManager";
import {BuyRequestModel} from "../../../../core/model";
import {Routes} from "../../../../core/router";


export function SystemManage_RequestList_Page() {
    const navigator = useNavigate();
    const [state, setState] = React.useState({
        loading: false,

        list: []
    });

    useEffect(() => {
        fetch();
    }, []);


    const fetch = () => {
        state.loading = true;
        setState({...state, state});
        SystemManager_BuyRequestRepo.all({count: 10})
            .then((res) => {
                try {
                    state.list = res.data.list.map((item) => {
                        let model = new ExModel;
                        model.dateTime = item.dateTime;
                        model.email = item.email || '';
                        model.requestId = Number(item.requestId || 0);
                        model.referralCode = item.referralCode || '';
                        model.referralPlan = item.referralPlan || '';
                        model.txh = item.txh || '';
                        model.uuid = item.uuid || '';
                        model.value = item.value || 0;
                        model.unitPrice_usd = item.unitPrice_usd || 0;
                        model.usdValue = item.usdValue || 0;
                        model.pendingCount = Number(item.pendingCount || 0);
                        return model;
                    });
                } catch (e) {
                    ToastHelper.showError();
                }
                state.loading = false;
                setState({...state, state});
            })
            .catch((err) => {

                ToastHelper.showError();
                state.loading = false;
                setState({...state, state});
            })
    };

    const renderTable = () => {
        return state.list.map((row, index) => {
            return <tr onClick={() => {
                navigator(Routes.systemManager.confirmTxh(row.txh))
            }}>
                <td>
                    {index + 1}
                </td>
                <td>
                    {(() => {
                        if (row.pendingCount) {
                            return <span className='yellow-color'>Pending ({row.pendingCount})</span>
                        } else {
                            return <span className='green-color'>Confirm</span>
                        }
                    })()}
                </td>

                <td>
                    {StandardHelper.decimal18(row.value)} BNB
                    <strong className='price-tag'>
                        ({StandardHelper.decimal18(row.usdValue, 2)} USD)
                    </strong>
                </td>
                <td>
                    {row.email}
                </td>
                <td>
                    {row.txh}
                </td>
                <td>
                    {row.dateTime}
                </td>

            </tr>
        })
    }

    return (
        <>
            <div className="SystemManage_RequestList_Page">
                <div className="card">
                    <div className="card-header">
                        <h4>
                            List of 50 top request ordering by date.
                        </h4>
                    </div>
                </div>
                <div className="card no-padding">
                    <div className="card-content no-padding">
                        <table className='table'>
                            <thead>
                            <tr>
                                <th>
                                    No.
                                </th>
                                <th>
                                    Condition
                                </th>
                                <th>
                                    Value
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Txh
                                </th>
                                <th>
                                    Date/Time
                                </th>


                            </tr>
                            </thead>
                            <tbody>
                            {renderTable()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

class ExModel extends BuyRequestModel {
    value: string = '';
    unitPrice_usd: number = 0;
    usdValue: number = 0;
    condition: string = '';
}
