import "./style.scss";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {AdminAuthHelper, StandardHelper} from "../../../../../core/helper";
import avatar from "assets/img/avatar.jpg";
import star from "assets/img/star.png";

import logo from "assets/logo/logo2.png";
import Button from "@mui/material/Button";
import {Icon, IconEnum} from "../../../../../core/components/icons/icons";
import {ConnectWalletHelper} from "../../../../../core/components/connectWallet";


import {BrowserProvider} from "ethers";
import {useWeb3ModalProvider, useWeb3ModalAccount} from '@web3modal/ethers/react'
import {Routes} from "../../../../../core/router";

export function AdminSideMenu() {
    const [open, setOpenMenu] = React.useState(false)
    const admin = AdminAuthHelper.getAdmin()
    const navigate = useNavigate();

    const {walletProvider} = useWeb3ModalProvider()
    const provider = new BrowserProvider(walletProvider)
    const {address, chainId, isConnected} = useWeb3ModalAccount()
    const startCreator = () => {
        return Array.apply(null, Array(Number(admin.level))).map(() => {
            return <img src={star}/>
        })
    }


    const hasAccess_SystemManager = () => {
        if (admin.accessAdminManager) {
            return <div className="item">
                <Link
                    onClick={() => {
                        setOpenMenu(false)
                    }}
                    to={Routes.systemManager.menu}>
                    System Manager
                </Link>
            </div>
        }
    }

    return (
        <>
            <div className={open ? 'open AdminSideMenu' : 'AdminSideMenu'}>
                <div className='logo-box'>
                    <div className="logo">
                        <img src={logo}/>
                        Trumpland Admin Panel (Beta)
                    </div>
                    <div className="menu-btn">
                        <Button className='icon-btn' onClick={() => {
                            setOpenMenu(!open)
                        }}>
                            <Icon name={IconEnum.more_v}/>
                        </Button>
                    </div>

                </div>
                <div className="admin-info">
                    <div className="avatar-box">
                        <img className='avatar' src={avatar}/>
                        <div className="star">
                            {startCreator()}
                        </div>
                    </div>
                    <div className="details">
                        <div className='rule'>
                            {admin.rule}
                        </div>
                        <div className='username'>
                            {admin.email}
                        </div>

                        <div className='credit'>
                            {StandardHelper.TLT_number(admin.financialBalance)}
                            <strong>
                                TLT
                            </strong>
                        </div>
                    </div>
                </div>
                <div className="menu">
                    <div className="item">
                        <Link
                            to={Routes.admin.dashboard}
                            onClick={() => {
                                setOpenMenu(false)
                            }}>
                            Dashboard
                        </Link>
                    </div>
                    <div className="item">
                        <Link
                            to={Routes.admin.profileInfo}
                            onClick={() => {
                                setOpenMenu(false)
                            }}>
                            Profile Information
                        </Link>
                    </div>
                    <div className="item">
                        <Link
                            to={Routes.admin.transactionHistory}
                            onClick={() => {
                                setOpenMenu(false)
                            }}>
                            Transactions History
                        </Link>
                    </div>
                    <div className="item">
                        <Link
                            onClick={() => {
                                setOpenMenu(false)
                            }}
                            to=''>
                            Marketing Plan
                        </Link>
                    </div>
                    <div className="item">
                        <Link
                            onClick={() => {
                                setOpenMenu(false)
                            }}
                            to={Routes.admin.adminPackageSelector}>
                            Packages
                        </Link>
                    </div>

                    {hasAccess_SystemManager()}

                    <div className="item">
                        <Link

                            to='' onClick={() => {
                            setOpenMenu(false)
                            AdminAuthHelper.removeAll();
                            navigate(Routes.admin.login);

                        }}>
                            Logout
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
