import React, {useEffect, useState} from 'react'
import './style.scss';
import {Icon, IconEnum, IconSizeEnum, WalletConnectButton} from "../..";
import {StandardHelper} from "../../../helper";

export function WalletInformationBox() {
    const [state, setState] = useState(window.wallet)

    useEffect((e) => {
        window.wallet.observable.subscribe((res) => {
            setState({...state, state})
        })


    }, [])

    const networkIcon = () => {
        switch (window.wallet.chainId) {
            case 1: {

                return <Icon name={IconEnum.chainLogo_Ethereum} size={IconSizeEnum.xs}/>
            }
            case 97: {

                return <Icon name={IconEnum.chainLogo_BNB_Test} size={IconSizeEnum.xs}/>
            }
            case 56: {
                return <Icon name={IconEnum.chainLogo_BNB} size={IconSizeEnum.xs}/>
            }
        }
    }

    return (() => {
        if (state.isConnected) {

            return <div className='WalletInformationBox' onClick={() => {
                window.wallet.open();
            }}>
                <div className="chain-box">
                    <div className='chain-icon'>
                        {networkIcon()}
                    </div>
                    <div className='balance'>
                        {StandardHelper.decimal18(window.wallet.balance, 3)}
                    </div>
                </div>

                <div className='wallet-address'>
                    {window.wallet.addressShort}
                </div>
            </div>
        } else {

            return <WalletConnectButton/>

        }
    })()

}


