import axios from "axios";
import {environment} from "../../_env";
import {AxiosInspector} from "../inspector";


export class AdminPersonalDataRepo {
    static route = `${environment.apiEndpoint}/admin/personal-info`

    static update(param) {
        AxiosInspector();
        return axios.put(`${this.route}`, param)
    }


}
