import img from 'assets/img/meta_2.jpg'
import './style.scss'


export function LandingPage_Meta2() {
    return (
        <section className="LandingPage_Meta2">
            <div className="master-content-frame">
                <div className="frame">

                    <div class="img">
                        <img src={img}/>
                    </div>
                    <div class="details">
                        <h2>
                            Aureus Metaverse now available on Oculus Meta Headset.
                        </h2>
                        <p>
                            With an unprecedented cooperation with Meta company, Trumpland successfully presented the first version of its Aureus platform on console versions of Oculus Meta virtual reality headset.
                        </p>
                        <p>
                            It is estimated that this unique product of Meta, Oculus and Trumpland will be released to the market in January 2025.
                        </p>
                    </div>

                    <div class="anime">

                    </div>
                </div>
            </div>
        </section>

    )
}
