export class StandardHelper {

    static number(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    static TLT_number(num) {
        if (!num) {
            return 0;
        }
        let floatSection = num.slice(num.length - 18, num.length - 12);
        let intSection = num.slice(0, num.length - 18);

        intSection = intSection || '0'
        return intSection.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (Number(floatSection) > 0 ? ('.' + Number(floatSection)) : '').toString();
    }

    static decimal18(num, decimalCount = null) {
        if (!num || num == 0) {
            if (decimalCount) {
                return '0.' + '0'.repeat(decimalCount)
            }
            return '0.00';
        }

        num = num / 1000000000000000000;
        // num = num / 10000000000000;
        let arr = num.toString().split('.')

        if (decimalCount && arr[1] && arr[1].length > 2) {
            arr[1] = arr[1].slice(0, decimalCount)
        }
        return arr[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (arr[1] ? ('.' + arr[1]) : '');

    }

    static round(num, decimalPlace = 2) {
        let dp = 10 ** decimalPlace;
        return (Math.round(num * dp) / dp).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


}
