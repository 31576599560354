import OfferImage from 'assets/img/offer.png'
import './style.scss'
import {GeneralRepository} from "../../../core/repository";
import React, {useEffect} from "react";


export function LandingPage_PriceSection() {
    const [state, setState] = React.useState({
        tltPrice: 0,
        tltPriceFrequency: 0,
        priceMinFrequency: 0,
        priceMaxFrequency: 0
    });
    useEffect(() => {
        GeneralRepository.currentPrice().subscribe((res) => {
            state.tltPrice = res.tltPrice;
            state.tltPriceFrequency = res.tltPrice;

            state.priceMinFrequency = Number(res.priceMinFrequency || 1);
            state.priceMaxFrequency = Number(res.priceMaxFrequency || 1);
            setState({...state, state})
            setInterval(() => {
                let rndInt = (state.priceMaxFrequency - state.priceMinFrequency) * Math.random() ;
                rndInt +=state.priceMinFrequency;
                state.tltPriceFrequency = rndInt + state.tltPrice;
                setState({...state, state})
            }, 5000)
        }, (err) => {

        })
    }, [])

    /*GeneralRepository.currentPrice()*/
    return (
        <section className='LandingPage_PriceSection'>
            <div className="master-content-frame">
                <div className="frame">
                    <h2>
                        Price
                    </h2>
                    <div className="box">
                        <div className="text">
                            <p>
                                Start your adventure in the Metaverse Aureus country for
                                ${Math.round(state.tltPriceFrequency * 10000000) / 10000000} per TLT
                            </p>

                            <div className="box">
                                <div className="price-base">
                                    <div className="tlt">
                                        1
                                        <strong>
                                            TLT
                                        </strong>
                                    </div>
                                    <div className="equal">
                                        =
                                    </div>

                                    <div className="tlt">
                                        <strong>
                                            $
                                        </strong>
                                        {Math.round(state.tltPriceFrequency * 10000000) / 10000000}
                                    </div>

                                </div>
                            </div>


                            <p className="offer">
                                {/*  Get
                                <strong>
                                    30%
                                </strong>
                                of the purchase amount as a bonus in BNB by purchasing any amount of Trumpland tokens
                                with the referral code.*/}
                            </p>

                        </div>
                        <div className="banner">
                            <img src={OfferImage}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
