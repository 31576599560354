import React from 'react'
import './style.scss';
import {WebsiteFooter, WebsiteHeader} from "../layout";
import img1 from 'assets/img/metaverse_01.jpg'
import img2 from 'assets/img/metaverse_02.jpg'
import img3 from 'assets/img/metaverse_03.jpg'
import img4 from 'assets/img/metaverse_04.jpg'
import img5 from 'assets/img/metaverse_05.jpg'
import img6 from 'assets/img/metaverse_06.jpg'


export function MetaversePage() {

    return (
        <>
            <WebsiteHeader/>
            <section className="MetaversePage">
                <div className="master-content-frame">
                    <div className="content">

                        <h1>
                            TrumpLand Metaverse
                        </h1>

                        <h3>
                            Welcome to TrumpLand Metaverse World
                        </h3>
                        <p>
                            The Aureus Metaverse comprises a dynamic country segmented into 21 distinct states, each
                            named after iconic global cities: Manhattan, Las Vegas, Santo Domingo, Brasília, Prague,
                            Rome, Berlin, Madrid, Paris, Stockholm, Belgrade, Bucharest, Kyiv, Moscow, Abu Dhabi, Delhi,
                            Jerusalem, Riyadh, Beijing, Tokyo, and Canberra. Within each state lies a key city, managed
                            by an appointed administrator tasked with driving profitability.
                        </p>
                        <img src={img1}/>
                        <p>
                            At the onset, the administrator doubles as the city's mayor, bearing the crucial
                            responsibility of fostering economic growth. Should the mayor falter in this role, a
                            resident from the same city is eligible to step in as a replacement.
                        </p>
                        <img src={img2}/>
                        <p>
                            Each state boasts a capital city, sharing its name with the state itself. The main country
                            administrator has the unique opportunity to acquire the capital. Here, the governor's
                            building stands as a symbol of authority, where the governor, essentially the main country
                            administrator, strives to enhance the capital's financial performance. A failure to achieve
                            this goal could lead to a new governor being elected through a mayoral vote.
                        </p>
                        <p>
                            The operational fabric of each city involves various expenses overseen by the mayor. This
                            includes essential services like electricity, law enforcement, and judiciary systems, each
                            financed through the city's revenues.
                        </p>
                        <img src={img3}/>
                        <p>
                            Administrators enjoy the flexibility to trade their acquired land via the Market Place,
                            setting their prices. However, Aureus recommends against selling, advising instead to
                            develop profitable structures on these lands. These properties can be rented or sold,
                            potentially yielding significant returns. Tenants and buyers alike share in the city's
                            profits, proportionate to their investment, with buyers typically reaping higher rewards.
                        </p>
                        <img src={img4}/>
                        <p>
                            Geographical diversity among the cities offers unique opportunities; for instance, coastal
                            cities can develop ports for lucrative import-export activities.
                        </p>
                        <p>
                            Governors, after elevating their capital's profitability, can opt to sell it to a mayor,
                            thereby acquiring a city in a more prosperous state for further development and gains.
                        </p>
                        <img src={img5}/>
                        <p>
                            Manhattan, not only the country's capital but also home to the governor's palace, holds a
                            pivotal role. Every two years, a presidential election steers Aureus towards further
                            progress and profitability.
                        </p>
                        <p>
                            In this expansive metaverse event, TLTs (the virtual currency) are essential for city
                            transactions and developments, poised to surge in value. Acquiring TLTs at lower rates is
                            strategic, setting the stage for significant future profits through city development and
                            management.
                        </p>
                        <img src={img6}/>

                        <div class="btn-holder">
                            <a className="go-to-metaverse-btn" href="https://wmt.trumpland.io">
                                Start Your Journey
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <WebsiteFooter/>
        </>

    );
}


