export class StorageHelper {


    static b64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            (match, p1) => {
                return String.fromCharCode(Number('0x' + p1));
            }));
    }

    static b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    static save(key, value) {
        localStorage.setItem(key, this.b64EncodeUnicode(JSON.stringify(value)));
    }

    static load(key) {
        try {
            return JSON.parse(this.b64DecodeUnicode(localStorage.getItem(key)));
        } catch (e) {
            return null;
        }
    }

    static remove(key) {
        localStorage.removeItem(key);
    }


    static saveTemporary(key, value) {
        sessionStorage.setItem(key, this.b64EncodeUnicode(JSON.stringify(value)));
    }

    static loadTemplate(key) {
        try {
            return JSON.parse(this.b64DecodeUnicode(sessionStorage.getItem(key)));
        } catch (e) {
            return null;
        }
    }

    static removeTemporary(key) {
        sessionStorage.removeItem(key);
    }


}
