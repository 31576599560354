import * as React from 'react';

import './style.scss'
import {AdminAuthHelper, DateTimeHelper, ToastHelper} from "../../../../core/helper";
import {TextField, Autocomplete} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdminPersonalDataRepo} from "../../../../core/repository/admin";
import {Routes} from "../../../../core/router";
import {useNavigate} from "react-router-dom";


export function AdminProfileInfoPage() {
    const admin = AdminAuthHelper.getAdmin();
    const navigate = useNavigate();

    const [state, setState] = React.useState({
        loading: false,
        label: {
            value: admin.label,
            isDirty: false,
            hasError: false
        }, firstName: {
            value: admin.firstName,
            isDirty: false,
            hasError: false
        }, lastName: {
            value: admin.lastName,
            isDirty: false,
            hasError: false
        }, birthday: {
            value: admin.birthday,
            isDirty: false,
            hasError: false
        }, country: {
            value: admin.country,
            isDirty: false,
            hasError: false
        }, city: {
            value: admin.city,
            isDirty: false,
            hasError: false
        }, email: {
            value: admin.email,
            isDirty: false,
            hasError: false
        },
    })


    const labelValidation = () => {
        state.label.isDirty = true;
        state.label.hasError = state.label.value.length < 2
        setState({...state, state})
    }
    const firstNameValidation = () => {
        state.firstName.isDirty = true;
        state.firstName.hasError = state.firstName.value.length < 3
        setState({...state, state})
    }
    const lastNameValidation = () => {
        state.lastName.isDirty = true;
        state.lastName.hasError = state.lastName.value.length < 3
        setState({...state, state})
    }
    const countryValidation = () => {
        state.country.isDirty = true;
        state.country.hasError = state.country.value.length < 3
        setState({...state, state})
    }
    const cityValidation = () => {
        state.city.isDirty = true;
        state.city.hasError = state.city.value.length < 3
        setState({...state, state})
    }
    const emailValidation = () => {
        state.email.isDirty = true;
        state.email.hasError = state.email.value.length < 3
        setState({...state, state})
    }



    const top100Films = [
        {label: 'Afghanistan', country: 'Afghanistan'},
        {label: 'Albania', country: 'Albania'},
        {label: 'Algeria', country: 'Algeria'},
        {label: 'Andorra', country: 'Andorra'},
        {label: 'Angola', country: 'Angola'},
        {label: 'Antigua and Barbuda', country: 'Antigua and Barbuda'},
        {label: 'Argentina', country: 'Argentina'},
        {label: 'Armenia', country: 'Armenia'},
        {label: 'Australia', country: 'Australia'},
        {label: 'Austria', country: 'Austria'},
        {label: 'Azerbaijan', country: 'Azerbaijan'},
        {label: 'Bahamas', country: 'Bahamas'},
        {label: 'Bahrain', country: 'Bahrain'},
        {label: 'Bangladesh', country: 'Bangladesh'},
        {label: 'Barbados', country: 'Barbados'},
        {label: 'Belarus', country: 'Belarus'},
        {label: 'Belgium', country: 'Belgium'},
        {label: 'Belize', country: 'Belize'},
        {label: 'Benin', country: 'Benin'},
        {label: 'Bhutan', country: 'Bhutan'},
        {label: 'Bolivia', country: 'Bolivia'},
        {label: 'Bosnia and Herzegovina', country: 'Bosnia and Herzegovina'},
        {label: 'Botswana', country: 'Botswana'},
        {label: 'Brazil', country: 'Brazil'},
        {label: 'Brunei', country: 'Brunei'},
        {label: 'Bulgaria', country: 'Bulgaria'},
        {label: 'Burkina Faso', country: 'Burkina Faso'},
        {label: 'Burundi', country: 'Burundi'},
        {label: 'Côte d`Ivoire', country: 'Côte d`Ivoire'},
        {label: 'Cabo Verde', country: 'Cabo Verde'},
        {label: 'Cambodia', country: 'Cambodia'},
        {label: 'Cameroon', country: 'Cameroon'},
        {label: 'Canada', country: 'Canada'},
        {label: 'Central African Republic', country: 'Central African Republic'},
        {label: 'Chad', country: 'Chad'},
        {label: 'Chile', country: 'Chile'},
        {label: 'China', country: 'China'},
        {label: 'Colombia', country: 'Colombia'},
        {label: 'Comoros', country: 'Comoros'},
        {label: 'Congo (Congo-Brazzaville)', country: 'Congo (Congo-Brazzaville)'},
        {label: 'Costa Rica', country: 'Costa Rica'},
        {label: 'Croatia', country: 'Croatia'},
        {label: 'Cuba', country: 'Cuba'},
        {label: 'Cyprus', country: 'Cyprus'},
        {label: 'Czechia (Czech Republic)', country: 'Czechia (Czech Republic)'},
        {label: 'Democratic Republic of the Congo', country: 'Democratic Republic of the Congo'},
        {label: 'Denmark', country: 'Denmark'},
        {label: 'Djibouti', country: 'Djibouti'},
        {label: 'Dominica', country: 'Dominica'},
        {label: 'Dominican Republic', country: 'Dominican Republic'},
        {label: 'Ecuador', country: 'Ecuador'},
        {label: 'Egypt', country: 'Egypt'},
        {label: 'El Salvador', country: 'El Salvador'},
        {label: 'Equatorial Guinea', country: 'Equatorial Guinea'},
        {label: 'Eritrea', country: 'Eritrea'},
        {label: 'Estonia', country: 'Estonia'},
        {label: 'Eswatini (fmr. "Swaziland")', country: 'Eswatini (fmr. "Swaziland")'},
        {label: 'Ethiopia', country: 'Ethiopia'},
        {label: 'Fiji', country: 'Fiji'},
        {label: 'Finland', country: 'Finland'},
        {label: 'France', country: 'France'},
        {label: 'Gabon', country: 'Gabon'},
        {label: 'Gambia', country: 'Gambia'},
        {label: 'Georgia', country: 'Georgia'},
        {label: 'Germany', country: 'Germany'},
        {label: 'Ghana', country: 'Ghana'},
        {label: 'Greece', country: 'Greece'},
        {label: 'Grenada', country: 'Grenada'},
        {label: 'Guatemala', country: 'Guatemala'},
        {label: 'Guinea', country: 'Guinea'},
        {label: 'Guinea-Bissau', country: 'Guinea-Bissau'},
        {label: 'Guyana', country: 'Guyana'},
        {label: 'Haiti', country: 'Haiti'},
        {label: 'Holy See', country: 'Holy See'},
        {label: 'Honduras', country: 'Honduras'},
        {label: 'Hungary', country: 'Hungary'},
        {label: 'Iceland', country: 'Iceland'},
        {label: 'India', country: 'India'},
        {label: 'Indonesia', country: 'Indonesia'},
        {label: 'Iran', country: 'Iran'},
        {label: 'Iraq', country: 'Iraq'},
        {label: 'Ireland', country: 'Ireland'},
        {label: 'Israel', country: 'Israel'},
        {label: 'Italy', country: 'Italy'},
        {label: 'Jamaica', country: 'Jamaica'},
        {label: 'Japan', country: 'Japan'},
        {label: 'Jordan', country: 'Jordan'},
        {label: 'Kazakhstan', country: 'Kazakhstan'},
        {label: 'Kenya', country: 'Kenya'},
        {label: 'Kiribati', country: 'Kiribati'},
        {label: 'Kuwait', country: 'Kuwait'},
        {label: 'Kyrgyzstan', country: 'Kyrgyzstan'},
        {label: 'Laos', country: 'Laos'},
        {label: 'Latvia', country: 'Latvia'},
        {label: 'Lebanon', country: 'Lebanon'},
        {label: 'Lesotho', country: 'Lesotho'},
        {label: 'Liberia', country: 'Liberia'},
        {label: 'Libya', country: 'Libya'},
        {label: 'Liechtenstein', country: 'Liechtenstein'},
        {label: 'Lithuania', country: 'Lithuania'},
        {label: 'Luxembourg', country: 'Luxembourg'},
        {label: 'Madagascar', country: 'Madagascar'},
        {label: 'Malawi', country: 'Malawi'},
        {label: 'Malaysia', country: 'Malaysia'},
        {label: 'Maldives', country: 'Maldives'},
        {label: 'Mali', country: 'Mali'},
        {label: 'Malta', country: 'Malta'},
        {label: 'Marshall Islands', country: 'Marshall Islands'},
        {label: 'Mauritania', country: 'Mauritania'},
        {label: 'Mauritius', country: 'Mauritius'},
        {label: 'Mexico', country: 'Mexico'},
        {label: 'Micronesia', country: 'Micronesia'},
        {label: 'Moldova', country: 'Moldova'},
        {label: 'Monaco', country: 'Monaco'},
        {label: 'Mongolia', country: 'Mongolia'},
        {label: 'Montenegro', country: 'Montenegro'},
        {label: 'Morocco', country: 'Morocco'},
        {label: 'Mozambique', country: 'Mozambique'},
        {label: 'Myanmar (formerly Burma)', country: 'Myanmar (formerly Burma)'},
        {label: 'Namibia', country: 'Namibia'},
        {label: 'Nauru', country: 'Nauru'},
        {label: 'Nepal', country: 'Nepal'},
        {label: 'Netherlands', country: 'Netherlands'},
        {label: 'New Zealand', country: 'New Zealand'},
        {label: 'Nicaragua', country: 'Nicaragua'},
        {label: 'Niger', country: 'Niger'},
        {label: 'Nigeria', country: 'Nigeria'},
        {label: 'North Korea', country: 'North Korea'},
        {label: 'North Macedonia', country: 'North Macedonia'},
        {label: 'Norway', country: 'Norway'},
        {label: 'Oman', country: 'Oman'},
        {label: 'Pakistan', country: 'Pakistan'},
        {label: 'Palau', country: 'Palau'},
        {label: 'Palestine State', country: 'Palestine State'},
        {label: 'Panama', country: 'Panama'},
        {label: 'Papua New Guinea', country: 'Papua New Guinea'},
        {label: 'Paraguay', country: 'Paraguay'},
        {label: 'Peru', country: 'Peru'},
        {label: 'Philippines', country: 'Philippines'},
        {label: 'Poland', country: 'Poland'},
        {label: 'Portugal', country: 'Portugal'},
        {label: 'Qatar', country: 'Qatar'},
        {label: 'Romania', country: 'Romania'},
        {label: 'Russia', country: 'Russia'},
        {label: 'Rwanda', country: 'Rwanda'},
        {label: 'Saint Kitts and Nevis', country: 'Saint Kitts and Nevis'},
        {label: 'Saint Lucia', country: 'Saint Lucia'},
        {label: 'Saint Vincent and the Grenadines', country: 'Saint Vincent and the Grenadines'},
        {label: 'Samoa', country: 'Samoa'},
        {label: 'San Marino', country: 'San Marino'},
        {label: 'Sao Tome and Principe', country: 'Sao Tome and Principe'},
        {label: 'Saudi Arabia', country: 'Saudi Arabia'},
        {label: 'Senegal', country: 'Senegal'},
        {label: 'Serbia', country: 'Serbia'},
        {label: 'Seychelles', country: 'Seychelles'},
        {label: 'Sierra Leone', country: 'Sierra Leone'},
        {label: 'Singapore', country: 'Singapore'},
        {label: 'Slovakia', country: 'Slovakia'},
        {label: 'Slovenia', country: 'Slovenia'},
        {label: 'Solomon Islands', country: 'Solomon Islands'},
        {label: 'Somalia', country: 'Somalia'},
        {label: 'South Africa', country: 'South Africa'},
        {label: 'South Korea', country: 'South Korea'},
        {label: 'South Sudan', country: 'South Sudan'},
        {label: 'Spain', country: 'Spain'},
        {label: 'Sri Lanka', country: 'Sri Lanka'},
        {label: 'Sudan', country: 'Sudan'},
        {label: 'Suriname', country: 'Suriname'},
        {label: 'Sweden', country: 'Sweden'},
        {label: 'Switzerland', country: 'Switzerland'},
        {label: 'Syria', country: 'Syria'},
        {label: 'Tajikistan', country: 'Tajikistan'},
        {label: 'Tanzania', country: 'Tanzania'},
        {label: 'Thailand', country: 'Thailand'},
        {label: 'Timor-Leste', country: 'Timor-Leste'},
        {label: 'Togo', country: 'Togo'},
        {label: 'Tonga', country: 'Tonga'},
        {label: 'Trinidad and Tobago', country: 'Trinidad and Tobago'},
        {label: 'Tunisia', country: 'Tunisia'},
        {label: 'Turkey', country: 'Turkey'},
        {label: 'Turkmenistan', country: 'Turkmenistan'},
        {label: 'Tuvalu', country: 'Tuvalu'},
        {label: 'Uganda', country: 'Uganda'},
        {label: 'Ukraine', country: 'Ukraine'},
        {label: 'United Arab Emirates', country: 'United Arab Emirates'},
        {label: 'United Kingdom', country: 'United Kingdom'},
        {label: 'United States of America', country: 'United States of America'},
        {label: 'Uruguay', country: 'Uruguay'},
        {label: 'Uzbekistan', country: 'Uzbekistan'},
        {label: 'Vanuatu', country: 'Vanuatu'},
        {label: 'Venezuela', country: 'Venezuela'},
        {label: 'Vietnam', country: 'Vietnam'},
        {label: 'Yemen', country: 'Yemen'},
        {label: 'Zambia', country: 'Zambia'},
        {label: 'Zimbabwe', country: 'Zimbabwe'},
    ];

    return (
        <>
            <div className="AdminProfileInfoPage">
                <div className="title">
                    <div className='card'>
                        <div className='card-header'>
                            <h4>
                                Profile Information
                            </h4>
                            <p>
                                You can control and modify your personal information in this section. Keep in mind that
                                this information will be applied in the system after the approval of the system
                                management.
                                <br/>
                                <strong className='red-color'>
                                    To access other parts of the system, you must first register your personal
                                    information completely in the section below
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="form">
                    <div className='card'>
                        <div className='card-header'/>
                        <div className="card-content">

                            <div className="form-box">
                                <TextField
                                    disabled={state.loading || admin.editLock}
                                    label="Title"
                                    placeholder="Enter your Title. ex (Mr, Mrs, Miss, ...)"
                                    variant="outlined"
                                    value={state.label.value}
                                    onChange={(e) => {
                                        state.label.value = e.target.value
                                        setState({...state, state})
                                    }}
                                    onBlur={labelValidation}
                                    error={state.label.hasError}
                                    helperText={state.label.hasError && state.label.isDirty ? 'Check your input!' : ' '}/>

                                <TextField
                                    disabled={state.loading || admin.editLock}
                                    label="First Name"
                                    placeholder="Enter your first name."
                                    variant="outlined"
                                    value={state.firstName.value}
                                    onChange={(e) => {
                                        state.firstName.value = e.target.value
                                        setState({...state, state})
                                    }}
                                    onBlur={firstNameValidation}
                                    error={state.firstName.hasError}
                                    helperText={state.firstName.hasError && state.firstName.isDirty ? 'Check your input!' : ' '}/>


                                <TextField
                                    disabled={state.loading || admin.editLock}
                                    label="Last Name"
                                    placeholder="Enter your last name."
                                    variant="outlined"
                                    value={state.lastName.value}
                                    onChange={(e) => {
                                        state.lastName.value = e.target.value
                                        setState({...state, state})
                                    }}
                                    onBlur={lastNameValidation}
                                    error={state.lastName.hasError}
                                    helperText={state.lastName.hasError && state.lastName.isDirty ? 'Check your input!' : ' '}/>


                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disabled={state.loading || admin.editLock}
                                        autoOk={true}
                                        onChange={(e, newInputValue) => {
                                            state.city.isDirty = true;
                                            if (newInputValue.validationError) {
                                                state.city.hasError = true
                                            } else {
                                                state.city.hasError = false;
                                                state.birthday.value = e.toDate()
                                                setState({...state, state})
                                            }
                                        }}
                                        defaultValue={dayjs(state.birthday.value)}
                                        inputFormat="yyyy.MM.dd"
                                        variant="outlined"
                                        label="Birthday"
                                        placeholder="Enter your birthday."
                                    />
                                </LocalizationProvider>

                                <Autocomplete
                                    disablePortal
                                    disabled={state.loading || admin.editLock}
                                    inputValue={state.country.value}

                                    value={state.country.value}

                                    onChange={(e, newInputValue) => {
                                        state.country.isDirty=false;
                                        if(newInputValue) {
                                            state.country.value = newInputValue
                                            state.country.hasError=false;
                                        }else{
                                            state.country.value='';
                                            state.country.hasError=true;
                                        }
                                        setState({...state, state})
                                    }}
                                    onInputChange={(e, newInputValue) => {

                                        state.country.value = newInputValue
                                        setState({...state, state})
                                    }}
                                    options={top100Films}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                       error={state.country.hasError}
                                                       helperText={state.city.hasError && state.city.isDirty ? 'Check your input!' : ' '}
                                                       label="Country"
                                                       onBlur={countryValidation}
                                                       placeholder="Enter your Country."/>}
                                />
                                <TextField
                                    disabled={state.loading || admin.editLock}
                                    label="City"
                                    placeholder="Enter your City."
                                    variant="outlined"
                                    value={state.city.value}
                                    onChange={(e) => {
                                        state.city.value = e.target.value
                                        setState({...state, state})
                                    }}
                                    onBlur={cityValidation}
                                    error={state.city.hasError}
                                    helperText={state.city.hasError && state.city.isDirty ? 'Check your input!' : ' '}/>

                                <TextField
                                    disabled={state.loading || admin.editLock}
                                    label="Email"
                                    placeholder="Enter your Email."
                                    variant="outlined"
                                    value={state.email.value}
                                    onChange={(e) => {
                                        state.email.value = e.target.value
                                        setState({...state, state})
                                    }}
                                    onBlur={emailValidation}
                                    error={state.email.hasError}
                                    helperText={state.email.hasError && state.email.isDirty ? 'Check your input!' : ' '}/>

                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className="action-box">
                                <div className="side">
                                    <LoadingButton
                                        disabled={admin.editLock}
                                        loading={state.loading}
                                        loadingPosition='center'
                                        variant="contained" className="primary-btn"
                                        onClick={() => {
                                            labelValidation();
                                            firstNameValidation();
                                            lastNameValidation();
                                            countryValidation();
                                            cityValidation();
                                            emailValidation();
                                            countryValidation();
                                            if (
                                                !state.label.hasError &&
                                                !state.firstName.hasError &&
                                                !state.lastName.hasError &&
                                                !state.birthday.hasError &&
                                                !state.country.hasError &&
                                                !state.city.hasError &&
                                                !state.email.hasError
                                            ) {
                                                state.loading = true;
                                                setState({...state, state});
                                                AdminPersonalDataRepo.update({
                                                    adminId: admin.adminId,
                                                    label: state.label.value,
                                                    firstName: state.firstName.value,
                                                    lastName: state.lastName.value,
                                                    birthday: DateTimeHelper.formatForServer(state.birthday.value, false),
                                                    country: state.country.value,
                                                    city: state.city.value,
                                                    email: state.email.value,
                                                }).then((res) => {
                                                    try {
                                                        AdminAuthHelper.setAdmin(res.data.admin)
                                                        AdminAuthHelper.setToken(res.data.token)
                                                        ToastHelper.showSuccess('Your profile information update successfully.')
                                                        navigate(Routes.admin.adminPackageSelector)
                                                    } catch (e) {
                                                        console.error(e);
                                                        ToastHelper.showError()
                                                    }
                                                    state.loading = false;
                                                    setState({...state, state});

                                                }).catch((err) => {
                                                    console.error(err);
                                                    ToastHelper.showError()
                                                    state.loading = false;
                                                    setState({...state, state});
                                                })
                                            }
                                        }}>
                                        Submit
                                    </LoadingButton>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </>
    );
}

