export const Routes = {
    admin: {
        login: '/admin/login',
        dashboard: '/admin',
        profileInfo: '/admin/profile-info',
        adminPackageSelector: '/admin/admin-package-selector',
        transactionHistory: '/admin/transaction-history',

    },
    systemManager: {
        menu: '/admin/system-manager',
        request: {
            list: '/admin/system-manager/request',
        },
        checkTxh: '/admin/system-manager/check-txh',
        confirmTxh: (txh: string) => `/admin/system-manager/confirm-txh/${txh}`
    },
    website: {
        landingPage: '/',
        metaverse: '/metaverse',
    }

};
