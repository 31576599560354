import React from 'react';
import logo from "assets/logo/logo2.png";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {Icon, IconEnum} from "../../../core/components/icons/icons";
import {HashDetailsDialog, HashInputDialog} from "../../../core/components/HashControllerDialog";
import {ConnectWalletComponent, WalletInformationBox} from "../../../core/components";
import {TransactionRepo} from "../../../core/repository";


export function WebsiteHeader() {


    const [state, setState] = React.useState({
        loading: false,
        checkHashDialog: false,
        hashDetailsDialog: false,
        menu: false,
        hashCodeNotFoundErrorMessage: '',
        model: {}
    });


    /* const menuToggle = () => {
         setTimeout(() => {
             setMenuOpen(!isMenuOpen)
         }, 200)
     }*/

    /*
        const openHashInputDialog = () => {
            setTimeout(() => {
                setHashInputDialogOpen(true)
            }, 10)
        };
        const closeHashInputDialog = () => {
            setTimeout(() => {
                setHashInputDialogOpen(false)
            }, 10)
        }
    */

    /*

      const handleClickOpen = () => {
          setOpen(true);
      };

      const handleClose = (value) => {
          setOpen(false);
          setSelectedValue(value);
      };*/


    return (
        <div className="siteHeaderComponent">
            <div className="master-content-frame">
                <div className="content">
                    <div className="logo">
                        <a href="/">
                            <img src={logo}/>
                            <div className="title">
                                TrumpLand
                            </div>
                        </a>
                    </div>
                    <div className="menu">
                        <div className="menu-btn">
                            <Button className='icon-btn' onClick={() => {
                                state.menu = !state.menu
                                setState({...state, state})
                            }}>
                                <Icon name={IconEnum.more_v}/>
                            </Button>
                        </div>
                        <div className={(state.menu ? 'open' : '') + " menu-content"}>
                            <div>
                                <Link to="/">
                                    Home
                                </Link>
                            </div>
                            <div>
                                <Link to="/contact-us">
                                    Contact Us
                                </Link>
                            </div>
                            <div>
                                <Link to="/about-us">
                                    About Us
                                </Link>
                            </div>
                            <div>
                                <Link to="/white-paper">
                                    White Paper
                                </Link>
                            </div>
                            <div className="btn">

                                <Button variant="outlined" className="primary-btn"
                                        onClick={() => {
                                            state.checkHashDialog = true;
                                            state.hashCodeErrorMessage = '';
                                            state.model = {};
                                            setState({...state, state});
                                        }}>
                                    Check Txh
                                </Button>

                                <HashInputDialog
                                    isOpen={state.checkHashDialog}
                                    loading={state.loading}
                                    errorMessage={state.hashCodeErrorMessage}
                                    onSubmit={(txh) => {

                                        state.loading = true;
                                        setState({...state, state});

                                        TransactionRepo.getByTxh(txh).then((res) => {
                                            if (res.request.hash) {
                                                state.model = res;

                                                state.checkHashDialog = false;
                                                state.hashDetailsDialog = true;
                                            } else {
                                                state.hashCodeErrorMessage = 'No record has been found!';
                                            }
                                            debugger
                                            state.loading = false;
                                            setState({...state, state});
                                        }).catch((err) => {
                                            state.loading = false;
                                            state.hashCodeErrorMessage = 'Something wrong! please try later';
                                            setState({...state, state});
                                        });

                                    }}
                                    onClose={() => {
                                        state.checkHashDialog = false;
                                        setState({...state, state});
                                    }}/>
                                <HashDetailsDialog
                                    model={state.model}
                                    isOpen={state.hashDetailsDialog}
                                    onClose={() => {
                                        state.hashDetailsDialog = false;
                                        setState({...state, state});
                                    }}
                                />
                                {/* {isHashInputDialogOpen ? <HashInputDialog onClose={(e) => {
                                    setHashInputDialogOpen(false);
                                }}/> : ''}*/}

                            </div>
                            <div className="btn">
                                <WalletInformationBox/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )

}

