import axios from "axios";
import {environment} from "../_env";


export class BuyRequestRepo {

    static route = `${environment.apiEndpoint}/request/buy-token`

    static insert(param: {
        txh: string,
        referralCode: string,
        email: string
    }) {
        return axios.post(this.route, param)
    }

    static insertDirectBuy(param: {
        txh: string,
        referralCode: string,
        email: string
    }) {
        return axios.post(`${this.route}/wallet-connect`, param)
    }
}
