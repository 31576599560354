import PosterImage from 'assets/img/poster-bg.jpg'
import Bg_Video from 'assets/video/bg.mp4'
import './style.scss'

export function LandingPage_MainSection() {
    return (
        <section className='LandingPage_MainSection'>


            <video
                poster={PosterImage}
                playsInline muted loop autoPlay>
                <source src={Bg_Video} type="video/mp4"/>
            </video>

            <div className="c1"></div>
            <div className="title">
                <h2>
                    Welcome To
                </h2>
                <h1>
                    TrumpLand Metaverse
                </h1>

                <h2>
                    Start differently, Win differently
                </h2>


            </div>


            <div className="c2"></div>
            <div className="c3"></div>


            <div className="firework-body">


                <div className="firework"></div>
                <div className="firework"></div>
                <div className="firework"></div>
            </div>


        </section>
    )

}
/*

import {Component, HostListener, OnInit,} from '@angular/core';


@Component({
    selector: 'MainSection2',
    templateUrl: './template.html',
    styleUrls: ['./style.scss']
})
export class MainSection2Controller implements OnInit {

    @HostListener('window:scroll', ['$event'])
    doSomething(event) {
        try {
            // @ts-ignore
            document.getElementById('BGVideoElement').play();
        } catch (e) {
        }
    }


    constructor() {

    }


    ngOnInit(): void {
        /!* setTimeout(() => {
           try {
             // @ts-ignore
             document.getElementById('BGVideoElement').play();
           }catch (e) {
           }
         }, 3000)*!/
    }


}
*/
