import React from 'react'
import Image from 'assets/img/tr-logo.jpg'
import './style.scss'
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {BuyRequestRepo} from "../../../core/repository";
import {useNavigate} from "react-router-dom";

export function LandingPage_BuyCryptoSection() {
    const [state, setState] = React.useState({
        referralCode: {
            value: '',
            hasError: false,
            isDirty: false
        },
        txh: {
            value: '',
            hasError: false,
            isDirty: false
        },
        email: {
            value: '',
            hasError: false,
            isDirty: false
        },
        loading: false
    });


    const referralValidation = () => {

        state.referralCode.isDirty = true;
        state.referralCode.hasError = state.referralCode.value.length !== 0 && state.referralCode.value.length !== 36 && state.referralCode.value.length !== 9
        setState({...state, state})
    };

    const txhValidation = () => {
        state.txh.isDirty = true;
        state.txh.hasError = state.txh.value.length !== 66
        setState({...state, state})
    };


    const emailValidation = () => {
        let regex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
        state.email.isDirty = true;
        state.email.hasError = !regex.test(state.email.value)
        setState({...state, state})

    };


    const navigate = useNavigate();

    return (
        <section className='LandingPage_BuyCryptoSection'>
            <div className="master-content-frame">
                <div className="frame">
                    <div className="input">
                        <div className="top">
                            <h2>
                                Buy Crypto

                            </h2>
                            <p>

                                You can buy the TLT token directly from the section below

                            </p>
                            {/*<p>
                                Keep in mind that by purchasing any amount of TLT with your
                                <strong className="yellow-color">
                                    {' '}referral code{' '}
                                </strong>
                                in our current plan
                                <strong className="yellow-color">
                                    {' '}(Solar)
                                </strong>
                                ,
                                <strong className="yellow-color">
                                    {' '} 30% BNB{' '}
                                </strong>
                                of the purchase will be credited to your referral accounts according
                                <strong className="yellow-color">
                                    {' '} two-step plan
                                </strong>
                                .
                            </p>*/}
                            <p>
                                <strong>
                                    Wallet:
                                </strong>
                                <br/>
                                <div className='wallet-address'>
                                    0x143045Aa790F0884C53De03f81d1Ad939c80ee38
                                </div>
                            </p>
                        </div>


                        <div className="text-input">
                            <TextField
                                disabled={state.loading}
                                label="Referral Code"
                                placeholder="Enter Your Referral Code"
                                variant="outlined"
                                onChange={(e) => {
                                    state.referralCode.value = e.target.value
                                    setState({...state, state})
                                }}
                                onBlur={referralValidation}
                                error={state.referralCode.hasError}
                                helperText={state.referralCode.hasError && state.referralCode.isDirty ? 'Check the value entered for "Referral Code"!' : ' '}/>
                        </div>

                        <div className="text-input">
                            <TextField
                                disabled={state.loading}
                                label="Transaction Hash Code (TxH)"
                                placeholder="Enter your Transaction Tx hash"
                                variant="outlined"
                                onChange={(e) => {
                                    state.txh.value = e.target.value
                                    setState({...state, state})
                                }}
                                onBlur={txhValidation}
                                error={state.txh.hasError}
                                helperText={state.txh.hasError && state.txh.isDirty ? 'Check the value entered for "Transaction Hash Code (TxH)"!' : ' '}/>
                        </div>


                        <div className="text-input">
                            <TextField
                                disabled={state.loading}
                                label="Email Address"
                                placeholder="Enter Your Valid Email Address"
                                variant="outlined"
                                onChange={(e) => {
                                    state.email.value = e.target.value
                                    setState({...state, state})
                                }}
                                onBlur={emailValidation}
                                error={state.email.hasError}
                                helperText={state.email.hasError && state.email.isDirty ? 'Check the value entered for "Email Address"!' : ' '}/>
                        </div>
                        <p className="info">
                            {/*info: You need to add TLT (BSC) contract token in your wallet from our website.*/}
                            info: You need to add TLT (BSC) contract token in your wallet.
                        </p>

                        <LoadingButton
                            variant="contained"
                            className="primary-btn"
                            loading={state.loading}
                            loadingPosition="center"
                            onClick={() => {

                                referralValidation();
                                txhValidation();
                                emailValidation();

                                if (
                                    !state.referralCode.hasError &&
                                    !state.txh.hasError &&
                                    !state.email.hasError

                                ) {
                                    state.loading = true;
                                    setState({...state, state})

                                    BuyRequestRepo.insert({
                                        txh: state.txh.value,
                                        referralCode: state.referralCode.value,
                                        email: state.email.value,
                                    }).then((res) => {
                                        try {
                                            navigate(`/confirm-registration/${res.data.requestUUID}`)
                                        } catch (e) {
                                            navigate(`/confirm-registration/error`)
                                        }
                                        state.loading = false;
                                        setState({...state, state})
                                    }).catch((err) => {

                                        try {
                                            if (err.response.data.code == 400 &&
                                                err.response.data.logRef == 1) {
                                                navigate(`/confirm-registration/error-not-found`);
                                            } else {
                                                navigate(`/confirm-registration/error`);
                                            }
                                        } catch (e) {
                                            navigate(`/confirm-registration/error`);

                                        }
                                        state.loading = false;
                                        setState(state)

                                    })
                                }
                            }}>
                            Submit
                        </LoadingButton>

                    </div>

                    <div className="logo">

                        <img src={Image}/>
                    </div>
                </div>
            </div>
        </section>

    )

}
