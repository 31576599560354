import {Subject} from 'rxjs';
import {
    useWeb3Modal,
    useWeb3ModalState,
    useWeb3ModalTheme,
    useWeb3ModalEvents,
    useWeb3ModalAccount,
    useWeb3ModalProvider,
    createWeb3Modal,
    defaultConfig,
    useDisconnect,
} from '@web3modal/ethers/react'

import {useEffect, useState} from "react";
import {BrowserProvider} from "ethers";
import {EthereumProvider} from "@walletconnect/ethereum-provider";
import {WalletHelper} from "./walletHelper";

/*import {
    isBrowser,
    isDesktop,
    isMobile,
    isTablet,
    isSmartTV,
    isConsole,
    isWearable,
    isEmbedded,
    isMobileSafari,
    isChromium,
    isMobileOnly,
    isAndroid,
    isWinPhone,
    isIOS,
    boolean,
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isIE,
    isEdge,
    isYandex,
    osVersion,
    osName,
    fullBrowserVersion,
    browserVersion,
    browserName,
    mobileVendor,
    mobileModel,
    engineName,
    engineVersion,
    getUA,
    deviceType,
    isIOS13,
    isIPad13,
    isIPhone13,
    isIPod13,
    isElectron,
    isEdgeChromium,
    isLegacyEdge,
    isWindows,
    isMacOs,
    isMIUI,
    isSamsungBrowser,
    OsTypes,
    BrowserTypes,
} from "react-device-detect";*/


export function WalletHook() {


    const projectId = WalletHelper.ProjectId;
    const modal = createWeb3Modal({
        ethersConfig: defaultConfig({
            name: 'Trumpland',
            description: 'My Website description',
            url: 'https://trumpland.io',
            icons: ['https://trumpland.io/android-chrome-512x512.png']
        }),
        defaultChain: [{
            chainId: 56,
            name: 'BNB Smart Chain',
            currency: 'BNB',
            explorerUrl: 'https://bscscan.com/',
            rpcUrl: 'https://bsc-dataseed.binance.org/'
        }],
        chains: [{
            chainId: 1,
            name: 'Ethereum',
            currency: 'ETH',
            explorerUrl: 'https://etherscan.io',
            rpcUrl: 'https://cloudflare-eth.com'
        }, {
            chainId: 56,
            name: 'BNB Smart Chain',
            currency: 'BNB',
            explorerUrl: 'https://bscscan.com/',
            rpcUrl: 'https://bsc-dataseed.binance.org/'
        }, {
            chainId: 97,
            name: 'BNB Smart Chain Testnet',
            currency: 'BNB',
            explorerUrl: 'https://testnet.bscscan.com/',
            rpcUrl: 'https://bsc-testnet.blockpi.network/v1/rpc/public/'
        }],

        projectId
    })


    const {open, close} = useWeb3Modal();
    const {disconnect} = useDisconnect();
    const {selectedNetworkId} = useWeb3ModalState();
    const {themeMode, themeVariables, setThemeMode, setThemeVariables} = useWeb3ModalTheme()
    const events = useWeb3ModalEvents()
    const {address, chainId, isConnected} = useWeb3ModalAccount();
    const {walletProvider} = useWeb3ModalProvider();
    const isDialogOpen = useWeb3ModalState().open;
    if (!window.wallet) {

        window.wallet = {
            isDialogOpen: false,
            loading: false,
            model: modal,
            address: '',
            addressShort: '',
            chainId: chainId,
            isConnected: false,
            useWeb3ModalAccount: useWeb3ModalAccount,
            open: open,
            themeMode: themeMode,
            themeVariables: themeVariables,
            setThemeMode: setThemeMode,
            setThemeVariables: setThemeVariables,
            ethereumProvider: EthereumProvider,
            events: events,
            close: close,
            disconnect: () => {
                if (disconnect) {
                    disconnect();
                    window.wallet.observable.next();
                }
            },
            selectedNetworkId: selectedNetworkId,
            walletProvider: walletProvider,
            provider: null,
            browserProvider: BrowserProvider,
            balance: 0,
            //browser:navigator.userAgent.includes("Chrome"),
            observable: new Subject()
        }
        /*alert(
            "isBrowser = " + isBrowser + '\n' +
            "isDesktop = " + isDesktop + '\n' +
            "isMobile = " + isMobile + '\n' +
            "isTablet = " + isTablet + '\n' +
            "isSmartTV = " + isSmartTV + '\n' +
            "isConsole = " + isConsole + '\n' +
            "isWearable = " + isWearable + '\n' +
            "isEmbedded = " + isEmbedded + '\n' +
            "isMobileSafari = " + isMobileSafari + '\n' +
            "isChromium = " + isChromium + '\n' +
            "isMobileOnly = " + isMobileOnly + '\n' +
            "isAndroid = " + isAndroid + '\n' +
            "isWinPhone = " + isWinPhone + '\n' +
            "isIOS = " + isIOS + '\n' +
            "boolean = " + boolean + '\n' +
            "isChrome = " + isChrome + '\n' +
            "isFirefox = " + isFirefox + '\n' +
            "isSafari = " + isSafari + '\n' +
            "isOpera = " + isOpera + '\n' +
            "isIE = " + isIE + '\n' +
            "isEdge = " + isEdge + '\n' +
            "isYandex = " + isYandex + '\n' +
            "osVersion = " + osVersion + '\n' +
            "osName = " + osName + '\n' +
            "fullBrowserVersion = " + fullBrowserVersion + '\n' +
            "browserVersion = " + browserVersion + '\n' +
            "browserName = " + browserName + '\n' +
            "mobileVendor = " + mobileVendor + '\n' +
            "mobileModel = " + mobileModel + '\n' +
            "engineName = " + engineName + '\n' +
            "engineVersion = " + engineVersion + '\n' +
            "getUA = " + getUA + '\n' +
            "deviceType = " + deviceType + '\n' +
            "isIOS13 = " + isIOS13 + '\n' +
            "isIPad13 = " + isIPad13 + '\n' +
            "isIPhone13 = " + isIPhone13 + '\n' +
            "isIPod13 = " + isIPod13 + '\n' +
            "isElectron = " + isElectron + '\n' +
            "isEdgeChromium = " + isEdgeChromium + '\n' +
            "isLegacyEdge = " + isLegacyEdge + '\n' +
            "isWindows = " + isWindows + '\n' +
            "isMacOs = " + isMacOs + '\n' +
            "isMIUI = " + isMIUI + '\n' +
            "isSamsungBrowser = " + isSamsungBrowser + '\n' +
            "OsTypes = " + OsTypes + '\n' +
            "BrowserTypes = " + BrowserTypes + '\n'
        );*/

    }


    const changedDetected = () => {
        if (window.wallet.isConnected) {
            window.wallet.provider = new BrowserProvider(walletProvider)
            window.wallet.provider.getBalance(address).then((res) => {
                window.wallet.balance = res.toString();
                window.wallet.observable.next();
            }).catch((error) => {

            })
        } else {
            window.wallet.observable.next();
        }
    }

    useEffect((e) => {
        window.wallet.chainId = chainId
        changedDetected();
    }, [chainId])

    useEffect((e) => {
        window.wallet.isConnected = isConnected
        changedDetected();
    }, [isConnected])

    useEffect((e) => {
        if (BrowserProvider) {
            window.wallet.browserProvider = BrowserProvider
            console.log('browserProvider update')
        }
    }, [BrowserProvider])

    useEffect((e) => {
        if (walletProvider) {
            window.wallet.walletProvider = walletProvider
            console.log('walletProvider update')
        }
    }, [walletProvider])


    useEffect((e) => {
        window.wallet.isDialogOpen = isDialogOpen;
        window.wallet.loading = isDialogOpen;
        window.wallet.observable.next();
    }, [isDialogOpen])


    useEffect((e) => {
        if (address) {
            window.wallet.address = address
            window.wallet.addressShort = address.slice(0, 4) + '...' + address.slice(address.length - 6, address.length)
        } else {
            window.wallet.address = ''
            window.wallet.addressShort = ''
        }

        changedDetected();
    }, [address])


}
