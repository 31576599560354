import React, {useEffect, useState} from 'react'
import './style.scss';
import {WebsiteFooter, WebsiteHeader} from "../layout";
import { WalletTransactionButton} from "../../core/components";

export function TestPage() {
    const [state, setState] = useState(window.wallet)

    useEffect((e) => {
        window.wallet.observable.subscribe((res) => {
            setState({...state, state})
        })

    }, [])


    return (
        <>
            <WebsiteHeader/>
            <section className="TestPage">

                <WalletTransactionButton/>
            </section>
            <WebsiteFooter/>
        </>

    );
}


