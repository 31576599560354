import axios from "axios";
import {environment} from "../../_env";
import {AxiosInspector} from "../inspector";


export class AdminPackageRepo {

    static route = `${environment.apiEndpoint}/admin/admin-package`

    static select(param) {
        AxiosInspector();
        return axios.post(`${this.route}/select-package`, param)
    }


}
