import axios from "axios";
import {AxiosInspector} from "../inspector";
import {environment} from "../../_env";

export class SystemManager_TransactionRepo {
    static route = `${environment.apiEndpoint}/system-manager/transaction`

    static confirm(param: {
        txh: string,
        transactionId: number,
    }) {
        AxiosInspector();
        return axios.post(`${this.route}/confirmation`, param)
    }

}
