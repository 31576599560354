
import './style.scss';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {WebsiteFooter, WebsiteHeader} from "../layout";

export function ContactUsPage() {

    return (
        <>
            <WebsiteHeader/>
            <section className="ContactUsComponent">
                <div className="master-content-frame">
                    <div className="content">

                        <h1>
                            Contact us
                        </h1>

                        <p>
                            We're here to help! If you have any questions, comments, or need assistance, please don't
                            hesitate to reach out
                            to us. Your satisfaction and feedback are crucial to our success.
                        </p>

                        <h3>
                            How to Reach Us:
                        </h3>

                        <p>
                            Email: For general inquiries or support, email us at
                            <a href="mailto:support@trumpland.io">
                                support@trumpland.io
                            </a>.
                        </p>
                        <p>
                            Contact Form: Fill out our online contact form, and we'll get back to you within 1-2
                            business days.
                        </p>

                        <h5>
                            Social Media:
                        </h5>
                        <p>
                            Follow us on Telegram, Twitter and Instagram for the latest updates and insights.
                        </p>

                        <p>
                            We look forward to hearing from you and are committed to providing a prompt and helpful
                            response.
                        </p>


                        <div className="form-box">
                            <form>
                                <div className="frame">
                                    <TextField id="outlined-basic" label="Name" variant="outlined"/>
                                    <TextField id="outlined-basic" label="Email" variant="outlined" pattern=".+@example\.com"/>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Write Message"
                                        multiline
                                        rows={4}
                                        defaultValue=""
                                    />

                                    <Button variant="contained" className="primary-btn">
                                        Submit
                                    </Button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
            <WebsiteFooter/>
        </>

    );
}



