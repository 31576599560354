import * as React from 'react';
import './style.scss'
import {Button} from "@mui/material";
import {StandardHelper, ToastHelper, ValidationHelper} from "../../../../core/helper";
import {TransactionRepo, TransactionGetModel} from "../../../../core/repository";

import {useParams} from "react-router-dom";
import {HashInputDialog} from "../../../../core/components/HashControllerDialog";

import {
    SystemManager_TransactionRepo
} from "../../../../core/repository/systemManager";


export function SystemManage_ConfirmTxh_Page() {


    const routerParam = useParams();
    const [state, setState] = React.useState({
        selectedTransactionId: 0,
        loading: false,
        showHashInputDialog: false,
        model: new TransactionGetModel()
    });

    React.useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        state.loading = true;
        setState({...state, state})
        TransactionRepo.getByTxh(routerParam.txh).then((res: TransactionGetModel) => {
            state.model = res;
            state.loading = false;
            setState({...state, state})
        }).catch((err) => {
            ToastHelper.showError();
            state.loading = false;
            setState(state)
        })
    }

    const txhValidation = () => {
        state.searchTxh.isDirty = true;
        state.searchTxh.hasError = ValidationHelper.checkTxhValidation(state.searchTxh.value);
        setState({...state, state})
    };


    const render = {
        request: () => {
            return <div className={'card ' + (state.loading ? 'loading' : '')}>
                <div className="card-header">
                    <h4>
                        Source Transaction.
                    </h4>
                </div>
                <div className="card-content">
                    <table>
                        <tr>
                            <td>
                                Txh:
                            </td>
                            <td>
                                {state.model.request.hash}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Value:
                            </td>
                            <td>
                                {StandardHelper.decimal18(state.model.request.value)} BNB
                                <strong className='price-tag'>
                                    ({StandardHelper.decimal18(state.model.request.usdValue, 2)} USD)
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Wallet Address:
                            </td>
                            <td>
                                {state.model.request.from}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Date\Time:
                            </td>
                            <td>
                                {state.model.request.dateTime}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Condition:
                            </td>
                            <td>
                                <strong className={state.model.request.condition}>
                                    {state.model.request.condition}
                                </strong>
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        },
        tltPayment: () => {
            return <div className={'card ' + (state.loading ? 'loading' : '')}>
                <div className="card-header">
                    <h4>
                        TLT Transaction.
                    </h4>
                </div>
                <div className="card-content">
                    <table>
                        <tr>
                            <td>
                                Txh:
                            </td>
                            <td>
                                {state.model.tltPayed.hash}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Value:
                            </td>
                            <td>
                                {StandardHelper.decimal18(state.model.tltPayed.value)} TLT
                                <strong className='price-tag'>
                                    ({StandardHelper.decimal18(state.model.tltPayed.usdValue, 2)} USD)
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Wallet Address:
                            </td>
                            <td>
                                {state.model.tltPayed.to}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Date\Time:
                            </td>
                            <td>
                                {state.model.tltPayed.dateTime}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Condition:
                            </td>
                            <td>
                                <strong className={state.model.tltPayed.condition}>
                                    {state.model.tltPayed.condition}
                                </strong>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="card-footer">
                    <div className="action-box">
                        <div className="side"/>
                        <div className="side">
                            <Button
                                disabled={state.model.tltPayed.condition === 'confirm'}
                                variant="contained"
                                className="white-btn"
                                onClick={() => {
                                    state.selectedTransactionId = state.model.tltPayed.id
                                    state.showHashInputDialog = true;
                                    setState({...state, state});
                                }}>
                                Confirm
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        },
        directParent: () => {
            if (state.model.directParent.id) {
                return <div className={'card ' + (state.loading ? 'loading' : '')}>
                    <div className="card-header">
                        <h4>
                            Direct Parent Commission.
                        </h4>
                    </div>
                    <div className="card-content">
                        <table>
                            <tr>
                                <td>
                                    Txh:
                                </td>
                                <td>
                                    {state.model.directParent.hash}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Value:
                                </td>
                                <td>
                                    {StandardHelper.decimal18(state.model.directParent.value)} BNB
                                    <strong className='price-tag'>
                                        ({StandardHelper.decimal18(state.model.directParent.usdValue, 2)} USD)
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Wallet Address:
                                </td>
                                <td>
                                    {state.model.directParent.to}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Date\Time:
                                </td>
                                <td>
                                    {state.model.directParent.dateTime}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Condition:
                                </td>
                                <td>
                                    <strong className={state.model.directParent.condition}>
                                        {state.model.directParent.condition}
                                    </strong>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="card-footer">
                        <div className="action-box">
                            <div className="side"/>
                            <div className="side">
                                <Button
                                    disabled={state.model.directParent.condition === 'confirm'}
                                    variant="contained"
                                    className="white-btn"
                                    onClick={() => {
                                        state.showHashInputDialog = true;
                                        state.selectedTransactionId = state.model.directParent.id
                                        setState({...state, state});
                                    }}>
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        },
        indirectParent: () => {
            if (state.model.inDirectParent.id) {
                return <div className={'card ' + (state.loading ? 'loading' : '')}>
                    <div className="card-header">
                        <h4>
                            Indirect Parent Commission.
                        </h4>
                    </div>
                    <div className="card-content">
                        <table>
                            <tr>
                                <td>
                                    Txh:
                                </td>
                                <td>
                                    {state.model.inDirectParent.hash}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Value:
                                </td>
                                <td>
                                    {StandardHelper.decimal18(state.model.inDirectParent.value)} BNB
                                    <strong className='price-tag'>
                                        ({StandardHelper.decimal18(state.model.inDirectParent.usdValue, 2)} USD)
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Wallet Address:
                                </td>
                                <td>
                                    {state.model.inDirectParent.to}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Date\Time:
                                </td>
                                <td>
                                    {state.model.inDirectParent.dateTime}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Condition:
                                </td>
                                <td>
                                    <strong className={state.model.inDirectParent.condition}>
                                        {state.model.inDirectParent.condition}
                                    </strong>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="card-footer">
                        <div className="action-box">
                            <div className="side"/>
                            <div className="side">
                                <Button
                                    disabled={state.model.inDirectParent.condition === 'confirm'}
                                    variant="contained"
                                    className="white-btn"
                                    onClick={() => {
                                        state.showHashInputDialog = true;
                                        state.selectedTransactionId = state.model.inDirectParent.id
                                        setState({...state, state});

                                    }}>
                                    Confirm
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            }
        }
    }
    return (
        <>
            <div className="SystemManage_ConfirmTxh_Page">


                {render.request()}

                {render.tltPayment()}

                {render.directParent()}


                {render.indirectParent()}


                <HashInputDialog
                    isOpen={state.showHashInputDialog}
                    onSubmit={(txh) => {
                        state.loading = true;
                        setState({...state, state});

                        SystemManager_TransactionRepo.confirm({
                            txh: txh,
                            transactionId: state.selectedTransactionId
                        }).then((res) => {
                            ToastHelper.showSuccess();
                            state.loading = false;
                            setState({...state, state});
                            fetch();
                        }).catch((err) => {
                            try {
                                ToastHelper.showError(err.response.data.message);
                            } catch (e) {
                                ToastHelper.showError();
                            }
                            state.loading = false;
                            setState({...state, state});
                        });
                        state.showHashInputDialog = false;
                        setState({...state, state});
                    }}
                    onClose={() => {
                        state.showHashInputDialog = false;
                        setState({...state, state});
                    }}/>
            </div>
        </>
    );
}

