import logo from "assets/logo/logo2.png";
import bg from "assets/img/admin/loginbg.jpg";
import {TextField} from '@mui/material'
import "./style.scss";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {AdminAuthRepo} from "../../../../core/repository/admin";
import {AdminAuthHelper} from "../../../../core/helper";
import {Navigate, useNavigate} from 'react-router-dom';
import {Routes} from "../../../../core/router";

export function AdminLoginPage() {

    const [state, setState] = React.useState({
        username: {
            value: '',
            hasError: false,
            isDirty: false
        },
        password: {
            value: '',
            hasError: false,
            isDirty: false
        },
        loading: false,
        errorMessage: ''
    })


    const usernameValidation = () => {
        state.username.isDirty = true;
        state.username.hasError = state.username.value.length === 0;
        setState({...state, state});
    }
    const passwordValidation = () => {
        state.password.isDirty = true;
        state.password.hasError = state.password.value.length < 3;
        setState({...state, state});
    }
    const navigate = useNavigate();


    const checkAdminToken = () => {
        return AdminAuthHelper.getToken() || false;
    }
    return (
        <>
            {
                checkAdminToken() ?
                    <Navigate to='/admin' replace/>
                    : ''
            }
            <div className="login">
                <div className="login-box-frame">

                    <div className="login-box">
                        <div className="banner">
                            <img src={logo}/>
                        </div>
                        <h1>
                            Admin Login
                        </h1>

                        <TextField
                            disabled={state.loading}
                            label="Username"
                            placeholder="Username"
                            variant="outlined"
                            onChange={(e) => {
                                state.username.value = e.target.value
                                setState({...state, state})
                            }}
                            onBlur={usernameValidation}
                            error={state.username.hasError}
                            helperText={state.username.hasError && state.username.isDirty ? 'Enter Username!' : ' '}/>

                        <TextField
                            disabled={state.loading}
                            label="Password"
                            placeholder="Password"
                            variant="outlined"
                            type="password"
                            onChange={(e) => {
                                state.password.value = e.target.value
                                setState({...state, state})
                            }}
                            onBlur={passwordValidation}
                            error={state.password.hasError}
                            helperText={state.password.hasError && state.password.isDirty ? 'Enter Password!' : ' '}/>

                        {
                            (state.errorMessage !== '') ?
                                <p className='error-message'>
                                    {state.errorMessage}
                                </p>
                                :
                                ''
                        }


                        <div className="btn-group">
                            <LoadingButton
                                variant="contained"
                                className="primary-btn"
                                loading={state.loading}
                                loadingPosition="center"
                                onClick={() => {
                                    usernameValidation();
                                    passwordValidation();

                                    if (
                                        !state.username.hasError &&
                                        !state.password.hasError
                                    ) {

                                        state.errorMessage = '';
                                        state.loading = true;
                                        setState({...state, state})

                                        AdminAuthRepo.login({
                                            username: state.username.value,
                                            password: state.password.value
                                        }).then((res) => {

                                            let isOk = true;
                                            if (res && res.data && res.data.admin) {
                                                if (res.data.admin.admin) {
                                                    AdminAuthHelper.setAdmin(res.data.admin.admin)
                                                } else {
                                                    isOk = false
                                                }
                                                if (res.data.admin.token) {
                                                    AdminAuthHelper.setToken(res.data.admin.token)
                                                } else {
                                                    isOk = false
                                                }
                                            } else {
                                                isOk = false
                                            }

                                            state.loading = false;
                                            setState({...state, state})
                                            if (isOk) {
                                                navigate(Routes.admin.dashboard)
                                            } else {

                                            }

                                        }).catch((err) => {

                                            state.loading = false;

                                            if (err && err.response && err.response.data) {
                                                if (err.response.data.code === 400 && err.response.data.logRef === 1) {
                                                    state.errorMessage = 'Username and password did not match!';
                                                } else {
                                                    state.errorMessage = 'Something wrong! please try later';
                                                }
                                            }else{
                                                state.errorMessage = 'Something wrong! please try later';
                                            }

                                            setState({...state, state})
                                        })
                                    }
                                }}>
                                Login
                            </LoadingButton>
                        </div>
                        <div className="footer">
                            © 2023 TrumpLand. All rights reserved
                        </div>
                    </div>
                    <div className="bg">
                        <img src={bg}/>
                    </div>
                </div>

            </div>
        </>
    )
}
