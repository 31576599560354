import {ToastHelper} from "../../helper";

export class WalletHelper {
    static ProjectId = '1475e419532e99e2a789cdf476b48ed2';

    static Transaction_getBNB(BNB_Value) {
        return new Promise((resolve, reject) => {
            const chainId = WalletChain.BNB_SmartChain;
            if (window.wallet.chainId === chainId) {

                window.wallet.provider = new window.wallet.browserProvider(window.wallet.walletProvider)
                window.wallet.provider.getSigner().then((signer) => {
                    if (signer) {
                        signer
                            .sendUncheckedTransaction({
                                from: window.wallet.address,
                                //to: "0x4CE22b0b573F76F85065901997C111b871E5DeAd",
                                to: "0x143045Aa790F0884C53De03f81d1Ad939c80ee38",
                                data: "0x",
                                //data: "0xd46e8dd67c5d32be8d46e8dd67c5d32be8058bb8eb970870f072445675058bb8eb970870f072445675",
                                //gasPrice: "0x029104e28c",
                                //gas: "0x5208",
                                gasLimit: 21000,
                                value: (BNB_Value * 1000000000000000000).toString(),
                                //nonce: "0x0114",
                            },)
                            .then((res) => {
                                resolve(res)
                            })
                            .catch((err) => {
                                ToastHelper.showError(WalletErrorHandler.translateByCode(err), 'Error')
                                reject(err)
                            })
                    } else {
                        ToastHelper.showError()
                        reject();
                    }
                }).catch((err) => {
                    ToastHelper.showError(WalletErrorHandler.translateByCode(err), 'Error')
                    reject(err)
                })
            } else {
                this.changeChainId(chainId).then((res) => {
                    this.Transaction_getBNB();
                });
            }
        });

    }

    static changeChainId(chain: WalletChain) {
        return new Promise((resolve, reject) => {
            window.wallet.walletProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: '0x' + chain.toString(16)}],
            }).then((res) => {
                resolve(res);
            }).catch((err) => {
                ToastHelper.showError(WalletErrorHandler.translateByCode(err), 'Error')
                reject(err);
            })
        })
    }
}

export class WalletErrorHandler {
    static translateByCode(err) {
        switch (err.code) {
            case 4001: {
                return 'User rejected the request.';
            }
            case -32002: {
                return 'Request already pending. Please wait.';
            }
            case -32602: {
                return 'Bad Request.';
            }
            default: {
                try {
                    return this.translateByCode(err.info.error)
                } catch (e) {

                }
            }
        }
    }
}

export const WalletChain = {
    Ethereum: 1,
    BNB_SmartChain: 56,
    BNB_SmartChain_Test: 97,
}
