export const environment = {
    appName: 'TrumpLand',
    appVersion: '1.0.0',
    companyName: 'TrumpLand',
    TLT: {
        contract: '0x033593ddB68DC825356D057b3f86b7611542e220',
        wallet: '0x143045Aa790F0884C53De03f81d1Ad939c80ee38',
    },
    socialNetworkLink: {
        telegram: '',//'https://t.me/trumplandtoken',
        twitter: 'https://twitter.com/trumplandtoken?s=21',
        instagram: '',//'https://www.instagram.com/invites/contact/?i=mhnpx021i7sf&utm_content=st5zh9u',
        mediumWhitePaper: 'https://medium.com/@hostmaster_59593/white-paper-metaverse-trumpland-project-a6696e3a82a6',
    },
    apiEndpoint: process.env.NODE_ENV === 'production' ? 'https://trumpland.io:9999/api' : 'http://localhost:9999/api',
    ApiEndpoint: process.env.NODE_ENV === 'production' ? 'https://trumpland.io:444/api' : 'http://127.0.0.1:444/api',
}
