import Dialog from '@mui/material/Dialog';
import {Button, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import {AdminTransactionRepo} from "../../repository/admin/adminTransaction";
import {ToastHelper} from "../../helper";
import './style.scss'
export function ConnectWallet_IncreaseCreditDialog(props) {
    const {onClose} = props;

    const [state, setState] = React.useState(
        {
            loading: false,
            transactionReferralCode: '',
            step: 1,
            hash: {
                isDirty: false,
                hasError: false,
                value: ''
            }
        }
    );

    const hashValidation = () => {
        state.hash.isDirty = true;
        state.hash.hasError = state.hash.value.length !== 66
        setState({...state, state})
    }

    return (

        <Dialog open={true}>
            <div className="dialog-header">
                Increase Credit
            </div>

            <div className="dialog-content ConnectWallet_IncreaseCreditDialog">

                {
                    (state.step === 1) ?
                        <div className="btn-group">
                            <Button
                                disabled
                                variant="contained" className="primary-btn contained" onClick={onClose}>
                                Connect Wallet
                            </Button>
                            <Button variant="contained" className="white-btn contained"
                                    onClick={() => {
                                        state.step = 2;
                                        setState({...state, state})
                                    }}>
                                Register Txh
                            </Button>
                        </div>
                        : ''
                }

                {
                    (state.step === 2) ?
                        <>
                            <p>
                                Please enter the hash code of your purchase transaction in the field below and press the submit button. Crediting your account takes between 1 minute and 24 hours.
                            </p>
                            <p>
                                Please enter your transaction hash code in below box:
                            </p>

                            <TextField
                                disabled={state.loading}
                                style={{"width": "100%"}}
                                label="Transaction Hash (Txh)"
                                onBlur={hashValidation}
                                error={state.hash.hasError}
                                helperText={state.hash.hasError && state.hash.isDirty ? 'Check the value entered for "Transaction Hash Code (Txh)"!' : ' '}
                                variant="outlined" onChange={(e, value) => {
                                state.hash.value = e.target.value;
                                setState({...state, state})

                            }}/>
                        </>
                        : ''
                }
                {
                    (state.step === 3) ?
                        <>
                            <p>
                                Your transaction was successfully registered with the code below. This registration is
                                confirmed within 1 minute to 24 hours.
                                If your account has not been credited after this time, contact Trumpland metaverse team
                                support at <a href='mailto:support@trumpland.io'>support@trumpland.io</a> emial.
                                <br/>
                                <br/>
                                <strong className='green-color'>
                                    {state.transactionReferralCode}
                                </strong>
                            </p>


                        </>
                        : ''
                }


            </div>

            <div className="dialog-footer">
                <div>
                    {
                        (state.step === 2) ?
                            <LoadingButton
                                loading={state.loading}
                                loadingPosition='center'
                                variant="contained"
                                className="primary-btn"

                                onClick={() => {
                                    hashValidation()
                                    if (!state.hash.hasError) {
                                        state.loading = true;
                                        setState({...state, state});

                                        AdminTransactionRepo.insert({
                                            hash: state.hash.value
                                        })
                                            .then((res) => {
                                                    state.transactionReferralCode = res.data.uuid
                                                    state.loading = false;
                                                    state.step = 3;
                                                    setState({...state, state});
                                                }
                                            )
                                            .catch((err) => {
                                                state.loading = false;
                                                setState({...state, state});
                                                ToastHelper.showError()
                                            })
                                    }
                                }}>
                                Submit
                            </LoadingButton>
                            : ''
                    }

                </div>
                <div>
                    <Button variant="contained" className="white-btn contained" onClick={onClose}>
                        Close
                    </Button>
                </div>
            </div>
        </Dialog>
    );

}
