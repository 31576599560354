import React from 'react'
import './style.scss';
import image from 'assets/img/404.jpg'
import {WebsiteFooter, WebsiteHeader} from "../layout";


export function Page404() {

    return (
        <>
            <WebsiteHeader/>
            <section className="Page404Controller">
                <div className="master-content-frame">
                    <div className="view">
                        <div className="content">
                            <h1>
                                404
                            </h1>
                            <p>
                                Sorry, the page not found!
                            </p>
                        </div>
                        <img src={image}/>


                    </div>
                </div>
            </section>
            <WebsiteFooter/>
        </>

    );
}


