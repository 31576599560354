import './style.scss'
import {BrowserProvider, Contract, ethers} from 'ethers'
import {
    useWeb3Modal,
    useWeb3ModalState,
    useWeb3ModalTheme,
    useWeb3ModalEvents,
    useWeb3ModalAccount,
    useWeb3ModalProvider,
    createWeb3Modal,
    defaultConfig,
} from '@web3modal/ethers/react'
import {EthereumProvider} from "@walletconnect/ethereum-provider";

export function ConnectWalletComponent() {
    return <></>
}
export function ConnectWalletComponent222() {
    const projectId = '1475e419532e99e2a789cdf476b48ed2'
    const modal = createWeb3Modal({
        ethersConfig: defaultConfig({
            name: 'Trumpland',
            description: 'My Website description',
            url: 'https://trumpland.io',
            icons: ['https://trumpland.io/android-chrome-512x512.png']
        }),
        defaultChain: [{
            chainId: 56,
            name: 'BNB Smart Chain',
            currency: 'BNB',
            explorerUrl: 'https://bscscan.com/',
            rpcUrl: 'https://bsc-dataseed.binance.org/'
        }],
        chains: [/*{
            chainId: 1,
            name: 'Ethereum',
            currency: 'ETH',
            explorerUrl: 'https://etherscan.io',
            rpcUrl: 'https://cloudflare-eth.com'
        },*/ {
            chainId: 56,
            name: 'BNB Smart Chain',
            currency: 'BNB',
            explorerUrl: 'https://bscscan.com/',
            rpcUrl: 'https://bsc-dataseed.binance.org/'
        }, {
            chainId: 97,
            name: 'BNB Smart Chain Testnet',
            currency: 'BNB',
            explorerUrl: 'https://testnet.bscscan.com/',
            rpcUrl: 'https://bsc-testnet.blockpi.network/v1/rpc/public/'
        }],
        projectId
    })

    const {open, close} = useWeb3Modal()
    const {selectedNetworkId} = useWeb3ModalState()
    const {themeMode, themeVariables, setThemeMode, setThemeVariables} = useWeb3ModalTheme()
    const events = useWeb3ModalEvents()
    const {address, chainId, isConnected} = useWeb3ModalAccount()
    const {walletProvider} = useWeb3ModalProvider()
    /*setThemeVariables({
        '--w3m-accent': 'ffaa00'
    })*/

    /* setThemeMode('dark')
     setThemeVariables({
         '--w3m-accent': '#FFAA00',
         //'--w3m-border-radius-master': '0.5px',
         //'--w3m-color-mix-strength': 40
         //'--w3m-button-hover-highlight-border-radius': 0
     })*/
    window.connectWallet = {
        address: address,
        isConnected: isConnected,
        chainId: chainId,
        EthereumProvider: EthereumProvider,
        walletProvider: walletProvider,
        createWeb3Modal: createWeb3Modal,
        defaultConfig: defaultConfig,
        Contract: Contract,
        ethers: ethers,
        open: open,
        events: events,
        provider: new BrowserProvider(walletProvider)
    }


    async function onSignMessage() {
        const provider = new BrowserProvider(walletProvider)
        const signer = await provider.getSigner()
        const signature = await signer?.signMessage('Hello Web3Modal Ethers')
        console.log(signature)
    }


    function onTransaction() {
        const provider = new BrowserProvider(walletProvider)
        provider.getSigner().then((signer) => {
            if (signer) {
                signer.sendUncheckedTransaction({
                    from: address,
                    to: "0x143045Aa790F0884C53De03f81d1Ad939c80ee38",
                    data: "0x",
                    //data: "0xd46e8dd67c5d32be8d46e8dd67c5d32be8058bb8eb970870f072445675058bb8eb970870f072445675",
                    //gasPrice: "0x029104e28c",
                    //gas: "0x5208",
                    value: 1 * 1000000000000000,
                    //nonce: "0x0114",
                },).then(
                    (result) => {

                    }
                ).catch((err) => {
                    console.error(err)
                })
            }
        }).catch((err) => {
            console.error(err)
        })
    }

    let value = 0.001;

    function BNBTransaction() {
        const provider = new BrowserProvider(walletProvider)
        provider.getSigner().then((signer) => {
            if (signer) {
                signer.sendUncheckedTransaction({
                    from: address,
                    to: "0x143045Aa790F0884C53De03f81d1Ad939c80ee38",
                    data: "0x",
                    //data: "0xd46e8dd67c5d32be8d46e8dd67c5d32be8058bb8eb970870f072445675058bb8eb970870f072445675",
                    //gasPrice: "0x029104e28c",
                    //gas: "0x5208",
                    gasLimit: 21000,
                    value: (value * 1000000000000000000).toString(),
                    //nonce: "0x0114",
                },).then(
                    (result) => {

                    }
                ).catch((err) => {
                    console.error(err)
                })
            }
        }).catch((err) => {
            console.error(err)
        })
    }

    function addToken() {


        EthereumProvider.init({
            projectId: projectId,
            chains: [56],
            showQrModal: true
        }).then((provider) => {
            provider.connect().then((res) => {

                provider.request({
                    method: "import_token",
                    params: {
                        type: 'ERC20',// "ERC20",
                        options: {
                            address: "0xd00981105e61274c8a5cd5a88fe7e037d935b513",
                            symbol: "TUT",
                            decimals: 18,
                            image: "http://placekitten.com/200/300"
                        }
                    }
                }).then((res) => {
                    console.log('Success')
                }).catch((err) => {
                    console.error(err)
                })
            }).catch((err) => {
                console.error(err)
            });
            /*
            provider.connect().then(
                (res) => {
                    provider.request({
                        "method": "wallet_watchAsset",
                        "params": {
                            "type": 'BEP20',// "ERC20",
                            "options": {
                                "address": "0x033593ddB68DC825356D057b3f86b7611542e220",
                                "symbol": "TLT",
                                "decimals": 18,
                                "image": "https://trumpland.io/assets/img/tlogo.png"
                            }
                        }
                    }).then((res) => {
                        debugger
                    }).catch((err) => {
                        debugger
                    })
                }
            ).catch((err) => {
                console.error(err)
            });*/
        }).catch((err) => {
            console.error(err)
        });


        /*const provider = new BrowserProvider(walletProvider)
        */
        /*provider.getSigner().then((signer) => {
            if (signer) {


                const test = new EthereumProvider();
                test.signer = signer;
                test.request({
                    "method": "wallet_watchAsset",
                    "params": {
                        "type": 'BEP20',// "ERC20",
                        "options": {
                            "address": "0x033593ddB68DC825356D057b3f86b7611542e220",
                            "symbol": "TLT",
                            "decimals": 18,
                            "image": "https://trumpland.io/assets/img/tlogo.png"
                        }
                    }
                }).then((res) => {
                    console.log(res);
                }).catch((err) => {
                    console.error(err)
                })
            }
        }).catch((err) => {
            console.error(err)
        })*/


    }

    function changeNetwork(chain) {
        window.wallet.ethereumProvider.init({
            projectId: this.ProjectId,
            chains: [chain],
            showQrModal: true
        }).then((provider) => {
            debugger;
            provider.connect().then((res) => {
                provider.switchEthereumChain(chain)
                    .then((res) => {
                        debugger;
                    })
                    .catch((err) => {
                        console.error(err)
                    });
            }).catch((err) => {
                console.error(err)
            });
            /*provider.connect().then((res) => {
                provider.request({
                    method: "import_token",
                    params: {
                        type: 'ERC20',// "ERC20",
                        options: {
                            address: "0xd00981105e61274c8a5cd5a88fe7e037d935b513",
                            symbol: "TUT",
                            decimals: 18,
                            image: "http://placekitten.com/200/300"
                        }
                    }
                }).then((res) => {
                    console.log('Success')
                }).catch((err) => {
                    console.error(err)
                })
            }).catch((err) => {
                console.error(err)
            });*/
        }).catch((err) => {
            console.error(err)
        });
    }
    function switchNetwork() {


        EthereumProvider.init({
            projectId: projectId,
            chains: [chainId],
            showQrModal: true
        }).then((provider) => {
            provider.connect().then((res) => {

                provider.request({
                    method: "import_token",
                    params: {
                        type: 'ERC20',// "ERC20",
                        options: {
                            address: "0xd00981105e61274c8a5cd5a88fe7e037d935b513",
                            symbol: "TUT",
                            decimals: 18,
                            image: "http://placekitten.com/200/300"
                        }
                    }
                }).then((res) => {
                    console.log('Success')
                }).catch((err) => {
                    console.error(err)
                })
            }).catch((err) => {
                console.error(err)
            });
            /*
            provider.connect().then(
                (res) => {
                    provider.request({
                        "method": "wallet_watchAsset",
                        "params": {
                            "type": 'BEP20',// "ERC20",
                            "options": {
                                "address": "0x033593ddB68DC825356D057b3f86b7611542e220",
                                "symbol": "TLT",
                                "decimals": 18,
                                "image": "https://trumpland.io/assets/img/tlogo.png"
                            }
                        }
                    }).then((res) => {
                        debugger
                    }).catch((err) => {
                        debugger
                    })
                }
            ).catch((err) => {
                console.error(err)
            });*/
        }).catch((err) => {
            console.error(err)
        });


        /*const provider = new BrowserProvider(walletProvider)
        */
        /*provider.getSigner().then((signer) => {
            if (signer) {


                const test = new EthereumProvider();
                test.signer = signer;
                test.request({
                    "method": "wallet_watchAsset",
                    "params": {
                        "type": 'BEP20',// "ERC20",
                        "options": {
                            "address": "0x033593ddB68DC825356D057b3f86b7611542e220",
                            "symbol": "TLT",
                            "decimals": 18,
                            "image": "https://trumpland.io/assets/img/tlogo.png"
                        }
                    }
                }).then((res) => {
                    console.log(res);
                }).catch((err) => {
                    console.error(err)
                })
            }
        }).catch((err) => {
            console.error(err)
        })*/


    }


    setTimeout(() => {
        try {
            let area = document.querySelector('w3m-button').shadowRoot.querySelector('w3m-connect-button').shadowRoot.querySelector('wui-connect-button').shadowRoot
            let btn = area.querySelector('button')
            btn.style.setProperty('--wui-color-accent-100', '#FFAA00')
            btn.style.setProperty('--wui-color-accent-090', '#FFAA00')
            btn.style.setProperty('--wui-color-accent-080', '#FFAA00')
            btn.style.setProperty('--wui-border-radius-m', '4px')

        } catch (e) {

        }
    }, 100)

    setTimeout(() => {
        try {
            let area = document.querySelector('w3m-button').shadowRoot.querySelector('w3m-account-button').shadowRoot.querySelector('wui-account-button').shadowRoot
            let btn = area.querySelector('button')
            btn.style.setProperty('--wui-border-radius-3xl', '4px')
            btn.style.setProperty('border-radius', '4px')
            btn.style.setProperty('font-size', '10px')

            let box1 = area.querySelector('button').querySelector('wui-flex').querySelector('wui-text').shadowRoot.querySelector('slot')
            box1.style.fontSize = '12px'
            let box2 = area.querySelector('button').querySelector('wui-text').shadowRoot.querySelector('slot')
            box2.style.fontSize = '12px'
        } catch (e) {

        }
    }, 500)


    return (
        <>

            <div className="connect-wallet-btn">
                <w3m-button/>
                <button onClick={BNBTransaction}>
                    click
                </button>
                {/*<button onClick={addToken}>
                    click
                </button>*/}
            </div>
        </>
    )


}

