export class ToastHelper {
    static duration = 8000

    static show(message='', title = 'Message') {
        this.showMessage('', title, message)
    }

    static showSuccess(message = 'The operation was done successfully!', title = 'Success') {
        this.showMessage('green', title, message)
    }

    static showError(message = 'An error has occurred in connection with the server!', title = 'Error') {
        this.showMessage('red', title, message)
    }

    static showInfo(message='', title = 'Info') {
        this.showMessage('blue', title, message)
    }

    static showWarning(message='', title = 'Warning') {
        this.showMessage('yellow', title, message)
    }


    static showMessage(type, title, message) {
        let box = this.createDom();
        let notifyBox = document.createElement('div');
        box.appendChild(notifyBox);

        notifyBox.innerHTML = `<div class="notify ${type}">
<div class="title">${title}</div>
    <div class="message">${message}</div>
</div>`

        this.setTimers(notifyBox)
    }

    static createDom() {
        let existBox = document.getElementsByClassName('ToastBox');
        if (existBox.length === 0) {
            let box = document.createElement('div');
            box.classList.add('ToastBox');
            let body = document.getElementsByTagName('body')[0];
            body.appendChild(box);
            return box
        } else {
            return existBox[0];
        }
    }

    static setTimers(notifyBox) {
        notifyBox.onclick = () => {
            this.remove(notifyBox)
        }
        setTimeout(() => {
            notifyBox.classList.add('show')
        }, 100)
        setTimeout(() => {
            this.remove(notifyBox)
        }, this.duration)
    }

    static remove(notifyBox) {
        notifyBox.classList.remove('show')
        setTimeout(() => {
            notifyBox.remove();
        }, 200)
    }
}
