import axios from "axios";
import {environment} from "../../_env";


export class AdminAuthRepo {

    static route = `${environment.apiEndpoint}/admin/auth`

    static login(param) {
        return axios.post(`${this.route}/login`, param)
    }


}
