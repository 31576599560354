import axios from "axios";
import {environment} from "../_env";

export class TransactionRepo {
    static route = `${environment.apiEndpoint}/transaction`

    static getByTxh(txh) {
        return new Promise((resolve, reject) => {

            axios.get(`${this.route}/txh/${txh}`)
                .then((res) => {
                    let model = new TransactionGetModel()
                    try {
                        model.fillData(res.data);
                        resolve(model)
                    } catch (e) {
                        reject(e)
                    }
                })
                .catch((err) => {
                    reject(err)
                });

        });


    }

    static test(){
        axios.get(`https://metamask.app.link/trumpland.io`,{responseType: 'document'})
    }
}

export class TransactionGetModel {
    request = {
        id: 0,
        sourceId: 0,
        dateTime: 0,
        hash: 0,
        from: 0,
        to: 0,
        value: 0,
        condition: 0,
        type: 0,
        cause: 0,
        description: 0,
        unitPrice_usd: 0,
        usdValue: 0,

    };
    tltPayed = {
        id: 0,
        sourceId: 0,
        dateTime: 0,
        hash: 0,
        from: 0,
        to: 0,
        value: 0,
        condition: 0,
        type: 0,
        cause: 0,
        description: 0,
        unitPrice_usd: 0,
        usdValue: 0,
    };
    directParent = {
        id: 0,
        sourceId: 0,
        dateTime: 0,
        hash: 0,
        from: 0,
        to: 0,
        value: 0,
        condition: 0,
        type: 0,
        cause: 0,
        description: 0,
        unitPrice_usd: 0,
        usdValue: 0,
    };
    inDirectParent = {
        id: 0,
        sourceId: 0,
        dateTime: 0,
        hash: 0,
        from: 0,
        to: 0,
        value: 0,
        condition: 0,
        type: 0,
        cause: 0,
        description: 0,
        unitPrice_usd: 0,
        usdValue: 0,
    };

    fillData(data) {
        this.request.id = data.request.id;
        this.request.sourceId = data.request.sourceId;
        this.request.dateTime = data.request.dateTime;
        this.request.hash = data.request.hash;
        this.request.from = data.request.from;
        this.request.to = data.request.to;
        this.request.value = data.request.value;
        this.request.condition = data.request.condition;
        this.request.type = data.request.type;
        this.request.cause = data.request.cause;
        this.request.description = data.request.description;
        this.request.unitPrice_usd = data.request.unitPrice_usd;
        this.request.usdValue = data.request.usdValue;


        this.tltPayed.id = data.tltPayed.id;
        this.tltPayed.sourceId = data.tltPayed.sourceId;
        this.tltPayed.dateTime = data.tltPayed.dateTime;
        this.tltPayed.hash = data.tltPayed.hash;
        this.tltPayed.from = data.tltPayed.from;
        this.tltPayed.to = data.tltPayed.to;
        this.tltPayed.value = data.tltPayed.value;
        this.tltPayed.condition = data.tltPayed.condition;
        this.tltPayed.type = data.tltPayed.type;
        this.tltPayed.cause = data.tltPayed.cause;
        this.tltPayed.description = data.tltPayed.description;
        this.tltPayed.unitPrice_usd = data.tltPayed.unitPrice_usd;
        this.tltPayed.usdValue = data.tltPayed.usdValue;

        this.directParent.id = data.directParent.id;
        this.directParent.sourceId = data.directParent.sourceId;
        this.directParent.dateTime = data.directParent.dateTime;
        this.directParent.hash = data.directParent.hash;
        this.directParent.from = data.directParent.from;
        this.directParent.to = data.directParent.to;
        this.directParent.value = data.directParent.value;
        this.directParent.condition = data.directParent.condition;
        this.directParent.type = data.directParent.type;
        this.directParent.cause = data.directParent.cause;
        this.directParent.description = data.directParent.description;
        this.directParent.unitPrice_usd = data.directParent.unitPrice_usd;
        this.directParent.usdValue = data.directParent.usdValue;


        this.inDirectParent.id = data.inDirectParent.id;
        this.inDirectParent.sourceId = data.inDirectParent.sourceId;
        this.inDirectParent.dateTime = data.inDirectParent.dateTime;
        this.inDirectParent.hash = data.inDirectParent.hash;
        this.inDirectParent.from = data.inDirectParent.from;
        this.inDirectParent.to = data.inDirectParent.to;
        this.inDirectParent.value = data.inDirectParent.value;
        this.inDirectParent.condition = data.inDirectParent.condition;
        this.inDirectParent.type = data.inDirectParent.type;
        this.inDirectParent.cause = data.inDirectParent.cause;
        this.inDirectParent.description = data.inDirectParent.description;
        this.inDirectParent.unitPrice_usd = data.inDirectParent.unitPrice_usd;
        this.inDirectParent.usdValue = data.inDirectParent.usdValue;
    }

}
