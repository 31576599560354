import Dialog from '@mui/material/Dialog';
import {Button, TextField} from "@mui/material";
import React from "react";
import {ValidationHelper} from "../../helper";
import {LoadingButton} from "@mui/lab";

export function HashInputDialog(props: {
    isOpen: boolean,
    onClose: ()=>{},
    onSubmit: (txh: string)=>{}

}= {
    isOpen:false,
    onClose :()=>{},
    onSubmit: (txh: string)=>{}
}) {
    const {isOpen, onClose, loading,errorMessage, onSubmit, selectedValue} = props;
    const [state, setState] = React.useState(
        {
            txh: {
                value: '',
                hasError: false,
                isDirty: false
            },
            loading: false,
            errorMessage: '',

        }
    );
    const txhValidation = () => {
        state.txh.isDirty = true;
        state.txh.hasError = ValidationHelper.checkTxhValidation(state.txh.value)
        setState({...state, state})
    }

    React.useEffect(() => {
        state.txh.value = '';
        state.txh.hasError = false;
        state.txh.isDirty = false;
        state.loading = false;
        state.errorMessage = '';
        setState({...state, state})

    }, [isOpen]);

    React.useEffect(() => {
        state.loading = loading;
        setState({...state, state})
    }, [loading]);

    React.useEffect(() => {
        state.errorMessage = errorMessage;
        setState({...state, state})
    }, [errorMessage]);

    return (

        <Dialog open={isOpen} className='HashInputDialog'>
            <div className="dialog-header">
                Transaction Controller
            </div>

            <div className="dialog-content ">
                <p>
                    Please enter your transaction hash code in below box:
                </p>


                <TextField
                    disabled={state.loading}
                    label="Transaction Hash Code (TxH)"
                    placeholder="Enter your Transaction Tx hash"
                    variant="outlined"
                    onChange={(e) => {
                        state.txh.value = e.target.value
                        setState({...state, state})
                    }}
                    onBlur={txhValidation}
                    error={state.txh.hasError}
                    helperText={state.txh.hasError && state.txh.isDirty ? 'Check the value entered for "Transaction Hash Code (TxH)"!' : ' '}/>
                <div className='red-color'>
                    {errorMessage}
                </div>
            </div>

            <div className="dialog-footer">
                <div>
                    <LoadingButton
                        loading={state.loading}
                        loadingPosition='center'
                        variant="contained"
                        className="primary-btn"
                        onClick={() => {
                            txhValidation()
                            if (!state.txh.hasError) {
                                state.loading = true;
                                setState({...state, state});
                                onSubmit(state.txh.value);
                            }
                        }}>
                        Submit
                    </LoadingButton>
                </div>
                <div>
                    <Button variant="contained" className="white-btn contained" onClick={onClose}>
                        Close
                    </Button>
                </div>
            </div>
        </Dialog>
    );

}
