import axios from "axios";
import {environment} from "../_env";
import {Subject} from "rxjs";


export class GeneralRepository {

    static route = `${environment.ApiEndpoint}/general`;

    static currentPrice() {
        const subject = new Subject()

        fetch(`${this.route}/current-price`, {
            method: "GET",
        }).then(response => response.json())
            .then(data => {
                subject.next(data);
                subject.complete();
                /*console.log(data);
                const posts = data.data.children.map(child => child.data);
                console.log(posts.map(post => post.title));*/
            })
            .catch(error => {
                subject.error(error);
            });

        return subject

    }
}
