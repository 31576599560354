import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import './style.scss'
import {StandardHelper} from "../../helper";
import {TransactionGetModel} from "../../repository";

const emails = ['username@gmail.com', 'user02@gmail.com'];


export function HashDetailsDialog(props) {
    let {isOpen, onClose, model} = props;


    if (!model) {
        model = new TransactionGetModel();
    }


    const requestRender = () => {
        if (model && model.request) {
            return <>
                <h3>
                    Transaction information:
                    {' '}
                    <span className={model.request.condition === 'confirm' ? 'green-color' : 'yellow-color'}>
                        {model.request.condition === 'confirm' ? 'Completed.' : 'Pending!'}
                    </span>
                </h3>
                <table>
                    <tr>
                        <td>
                            Txh:
                        </td>
                        <td>
                            {model.request.hash}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Value:
                        </td>
                        <td>
                            {StandardHelper.decimal18(model.request.value)} BNB
                            <strong className='price-tag'>
                                ({StandardHelper.decimal18(model.request.usdValue, 2)} USD)
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Wallet Address:
                        </td>
                        <td>
                            {model.request.from}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Date\Time:
                        </td>
                        <td>
                            {model.request.dateTime}
                        </td>
                    </tr>
                </table>
                <hr/>
            </>
        }
    }
    const tltPayment = () => {
        if (model && model.tltPayed) {
            return <div>
                <h4>
                    Transaction information (TLT):
                    {' '}
                    <span className={model.tltPayed.condition === 'confirm' ? 'green-color' : 'yellow-color'}>
                        {model.tltPayed.condition === 'confirm' ? 'Completed.' : 'Pending!'}
                    </span>
                </h4>
                <table>
                    <tr>
                        <td>
                            Txh:
                        </td>
                        <td>
                            {model.tltPayed.hash}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Value:
                        </td>
                        <td>
                            {StandardHelper.decimal18(model.tltPayed.value)} TLT
                            <strong className='price-tag'>
                                ({StandardHelper.decimal18(model.tltPayed.usdValue, 2)} USD)
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Wallet Address:
                        </td>
                        <td>
                            {model.tltPayed.to}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Date\Time:
                        </td>
                        <td>
                            {model.tltPayed.dateTime}
                        </td>
                    </tr>

                </table>

            </div>
        }
    };
    const directParentRender = () => {
        if (model) {
            let dp = model.directParent;
            if (dp) {
                return <div>
                    <hr/>
                    <h4>
                        Level 1 commission:

                        {' '}
                        <span className={model.directParent.condition === 'confirm' ? 'green-color' : 'yellow-color'}>
                            {model.directParent.condition === 'confirm' ? 'Completed.' : 'Pending!'}
                        </span>
                    </h4>

                    <table>
                        <tr>
                            <td>
                                Txh:
                            </td>
                            <td>
                                {model.directParent.hash}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Value:
                            </td>
                            <td>
                                {StandardHelper.decimal18(model.directParent.value)} BNB
                                <strong className='price-tag'>
                                    ({StandardHelper.decimal18(model.directParent.usdValue, 2)} USD)
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Wallet Address:
                            </td>
                            <td>
                                {model.directParent.to}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Date\Time:
                            </td>
                            <td>
                                {model.directParent.dateTime}
                            </td>
                        </tr>
                    </table>
                </div>
            }
        }
    };
    const indirectParentRender = () => {
        if (model) {
            let dp = model.inDirectParent;
            if (dp) {
                return <div>
                    <hr/>
                    <h4>
                        Level 2 commission:
                        {' '}
                        <span className={model.inDirectParent.condition === 'confirm' ? 'green-color' : 'yellow-color'}>
                            {model.inDirectParent.condition === 'confirm' ? 'Completed.' : 'Pending!'}
                        </span>

                    </h4>
                    <table>
                        <tr>
                            <td>
                                Txh:
                            </td>
                            <td>
                                {model.inDirectParent.hash}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Value:
                            </td>
                            <td>
                                {StandardHelper.decimal18(model.inDirectParent.value)} BNB
                                <strong className='price-tag'>
                                    ({StandardHelper.decimal18(model.inDirectParent.usdValue, 2)} USD)
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Wallet Address:
                            </td>
                            <td>
                                {model.inDirectParent.to}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Date\Time:
                            </td>
                            <td>
                                {model.inDirectParent.dateTime}
                            </td>
                        </tr>
                    </table>
                </div>
            }
        }
    };

    return (
        <Dialog open={isOpen}>
            <div className="dialog-header">
                Transaction Controller
            </div>

            <div className="dialog-content ">
                <div className="HashDetailsDialog">


                    {requestRender()}
                    {tltPayment()}

                    {directParentRender()}

                    {indirectParentRender()}

                </div>
            </div>

            <div className="dialog-footer">
                <div>

                </div>
                <div>
                    <Button variant="contained" className="white-btn contained" onClick={() => {
                        if (onClose) {
                            onClose()
                        }
                    }}>
                        Close
                    </Button>
                </div>
            </div>
        </Dialog>
    )
        ;
}
