import {Button, Dialog} from '@mui/material'
import * as React from "react";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {TextField} from "@mui/material";
import './style.scss'
import {BuyRequestRepo, GeneralRepository} from "../../repository";
import {StandardHelper, ToastHelper, ValidationHelper} from "../../helper";
import {useEffect} from "react";
import {WalletHelper} from "../wallet";
import {WalletChain} from "../wallet/walletHelper";
import {useNavigate} from "react-router-dom";

export function Buy_TLT_Dialog(props: {
    open: boolean,
    onClose: ()=>{},
} = {
    open: false,
    onClose: () => {
    },
}) {

    const navigate = useNavigate()
    const {open, onClose} = props;
    const [state, setState] = useState({
        loading: false,
        BNB_price: 0,
        TLT_price: 0,
        totalPrice: {
            USD: 0,
            TLT: 0
        },
        referralCode: {
            value: '',
            errorMessage: '',
            isDirty: false
        },
        email: {
            value: '',
            errorMessage: '',
            isDirty: false
        },
        bnbValue: {
            value: '0',
            errorMessage: '',
            isDirty: false
        },

        wallet: window.wallet
    });


    const reset = () => {
        state.totalPrice.USD = 0;
        state.totalPrice.TLT = 0;

        state.referralCode.value = '';
        state.referralCode.errorMessage = '';
        state.referralCode.isDirty = false;

        state.email.value = '';
        state.email.errorMessage = '';
        state.email.isDirty = false;

        state.bnbValue.value = '';
        state.bnbValue.errorMessage = '';
        state.bnbValue.isDirty = false;

        setState({...state, state});
    };

    useEffect(() => {
        if (open) {
            reset();
            fetchCurrentPrice();
        }
    }, [open]);

    const fetchCurrentPrice = () => {
        state.loading = true;
        setState({...state, state});
        GeneralRepository
            .currentPrice()
            .then((res) => {
                try {
                    state.BNB_price = Number(res.data.BNB_price || 0);
                    state.TLT_price = Number(res.data.TLT_price || 0);
                    WalletHelper.changeChainId(WalletChain.BNB_SmartChain)
                        .then((res) => {
                            state.loading = false;
                            setState({...state, state});
                        })
                        .catch((err) => {
                            state.loading = false;
                            setState({...state, state});
                            onClose();
                        })
                } catch (e) {
                    ToastHelper.showError();
                    state.loading = false;
                    setState({...state, state});
                    onClose();
                }

            })
            .catch((err) => {
                ToastHelper.showError()
                state.loading = false;
                setState({...state, state});
                onClose();
            })
    };
    const referralValidation = () => {
        state.referralCode.isDirty = true;
        state.referralCode.errorMessage = ValidationHelper.checkReferralCodeValidation(state.referralCode.value).errorMessage;
        setState({...state, state});
    };


    const emailValidation = () => {
        state.email.isDirty = true;
        state.email.errorMessage = ValidationHelper.checkEmailValidation(state.email.value).errorMessage;
        setState({...state, state});
    };

    const bnbValueValidation = () => {
        state.bnbValue.isDirty = true;
        state.bnbValue.errorMessage = '';
        if (Number(state.bnbValue.value) <= 0) {
            state.bnbValue.errorMessage = 'Please add some amount for this purchase.'
        }
        if (state.bnbValue.value > window.wallet.balance) {
            state.bnbValue.errorMessage = 'You do not have enough amount for this purchase in your wallet.'
        }
        setState({...state, state});
    };

    const calculate = () => {
        state.totalPrice.USD = Number(state.bnbValue.value || 0) * state.BNB_price;
        state.totalPrice.TLT = state.totalPrice.USD / state.TLT_price;
        bnbValueValidation();
        setState({...state, state});
    };

    const insert = (txh: string) => {
        state.loading = true;
        setState({...state, state})
        BuyRequestRepo.insertDirectBuy({
            txh: txh,
            referralCode: state.referralCode.value,
            email: state.email.value,
        }).then((res) => {
            try {
                navigate(`/confirm-registration/${res.data.requestUUID}`)
            } catch (e) {
                navigate(`/confirm-registration/error`)
            }
            state.loading = false;
            setState({...state, state})
        }).catch((err) => {

            try {
                if (err.response.data.code == 400 &&
                    err.response.data.logRef == 1) {
                    navigate(`/confirm-registration/error-not-found`);
                } else {
                    navigate(`/confirm-registration/error`);
                }
            } catch (e) {
                navigate(`/confirm-registration/error`);

            }
            state.loading = false;
            setState(state)

        })
    };


    return (

        <Dialog
            open={open}
            color-mode='dark'
            loading={state.loading ? 'true' : 'false'}
            dialog-name='Buy_TLT_Dialog'>
            <div className="dialog-content">
                <h1>
                    Buy TLT (Beta)
                </h1>
                <p>
                    Get your TLT with direct purchase.
                    <br/>
                    All you have to do is enter your Referral code, Email address and the Value of the BNB that you want
                    to exchange, in below fields.
                    <br/><br/>
                    <strong>
                        You need to add TLT (BSC) contract token in your wallet.
                    </strong>

                </p>

                <div className="input-frame">
                    <TextField
                        disabled={state.loading}
                        label="Referral Code"
                        placeholder="Enter Your Referral Code"
                        variant="outlined"
                        onChange={(e) => {
                            state.referralCode.value = e.target.value
                            setState({...state, state})
                        }}
                        onBlur={referralValidation}
                        error={state.referralCode.errorMessage.length > 0}
                        helperText={state.referralCode.errorMessage && state.referralCode.isDirty ? state.referralCode.errorMessage : ' '}/>
                </div>
                <div className="input-frame">
                    <TextField
                        disabled={state.loading}
                        label="Email Address"
                        placeholder="Enter Your Valid Email Address"
                        variant="outlined"
                        onChange={(e) => {
                            state.email.value = e.target.value
                            setState({...state, state})
                        }}
                        onBlur={emailValidation}
                        error={state.email.errorMessage.length > 0}
                        helperText={state.email.isDirty && state.email.errorMessage ? state.email.errorMessage : ' '}/>
                </div>


                <div className="input-frame">
                    <TextField

                        type="number"
                        disabled={state.loading}
                        label="Amount In BNB"
                        placeholder="Enter Your Amount In BNB"
                        variant="outlined"
                        value={state.bnbValue.value}
                        onChange={(e) => {
                            state.bnbValue.value = e.target.value
                            bnbValueValidation();
                            calculate()
                        }}
                        onBlur={bnbValueValidation}
                        error={state.bnbValue.errorMessage.length > 0}
                        helperText={state.bnbValue.errorMessage && state.bnbValue.isDirty ? state.bnbValue.errorMessage : ' '}/>


                </div>
                <div className="percent-buttons">
                    <div className="side">
                        <div className="balance-box">
                            Balance:
                            {' '}
                            <strong>
                                {StandardHelper.decimal18(state.wallet.balance)}
                            </strong>
                            {' '}
                            BNB
                            <br/>
                            <br/>
                            BNB Price :{' '}
                            <strong>
                                {StandardHelper.round(state.BNB_price)}
                            </strong>
                            {' '}$
                            <br/>

                            USD :{' '}
                            <strong>
                                {StandardHelper.round(state.totalPrice.USD)}
                            </strong>
                            {' '}$

                            <br/>
                            TLT Price :{' '}(
                            <strong>
                                {state.TLT_price}
                            </strong>
                            ) $ / {' '}
                            <strong>
                                {StandardHelper.round(state.totalPrice.TLT)}
                            </strong>
                            {' '} TLT

                        </div>

                    </div>
                    <div className="side">
                        <Button
                            variant="outlined"
                            className="white-btn"
                            onClick={() => {
                                state.bnbValue.value = StandardHelper.decimal18(window.wallet.balance * 0.25)
                                calculate();
                            }}
                        >
                            25%
                        </Button>
                        <Button
                            variant="outlined"
                            className="white-btn"
                            onClick={() => {
                                state.bnbValue.value = StandardHelper.decimal18(window.wallet.balance * 0.5)
                                calculate();
                            }}
                        >
                            50%
                        </Button>
                        <Button
                            variant="outlined"
                            className="white-btn"
                            onClick={() => {
                                state.bnbValue.value = StandardHelper.decimal18(window.wallet.balance * 0.75)
                                calculate();
                            }}
                        >
                            75%
                        </Button>
                        <Button
                            variant="outlined"
                            className="primary-btn"
                            onClick={() => {
                                state.bnbValue.value = StandardHelper.decimal18(window.wallet.balance)
                                calculate();
                            }}
                        >
                            MAX
                        </Button>
                    </div>
                </div>
                <p className='light-text'>
                    <strong>
                        Caution:
                    </strong>
                    Keep in mind that these values are not completely accurate and at the time of registration and
                    transfer, the exact value is calculated according to the exact price.
                </p>
            </div>
            <div className="dialog-footer">
                <div>
                    <LoadingButton
                        loading={state.loading}
                        loadingPosition='center'
                        variant="contained"
                        className="primary-btn"
                        onClick={() => {
                            referralValidation();
                            emailValidation();
                            bnbValueValidation();

                            if (
                                state.referralCode.errorMessage.length === 0 &&
                                state.email.errorMessage.length === 0 &&
                                state.bnbValue.errorMessage.length === 0
                            ) {
                                state.loading = true;
                                setState({...state, state});
                                WalletHelper.Transaction_getBNB(state.bnbValue.value)
                                    .then((res) => {

                                        insert(res);

                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    })
                            }
                        }}>
                        Submit
                    </LoadingButton>
                </div>
                <div>
                    <Button variant="contained" className="white-btn contained" onClick={onClose}>
                        Close
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
