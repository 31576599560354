import React from 'react'
import './style.scss';
import {WebsiteFooter, WebsiteHeader} from "../layout";


export function AboutUsPage() {

    return (
        <>
            <WebsiteHeader/>
            <section className="AboutUsController">
                <div className="master-content-frame">
                    <div className="content">

                        <h1>
                            About us
                        </h1>

                        <h3>
                            Welcome to TrumpLand
                        </h3>
                        <p>
                            Established with a visionary goal, our mission transcends the boundaries of mere financial
                            transactions. We are
                            dedicated to tackling the intricate challenge of communication and unification of human
                            identity in the digital
                            age. Our approach is as unique as our goal: we operate through specialized teams
                            strategically located in 15
                            countries across the globe, embracing the power of decentralization in our workflow.
                        </p>


                        <p>
                            Our journey began in the bustling city of Manhattan, a melting pot of cultures and ideas
                            that inspired our
                            founding ethos. Today, we stand at the forefront of an ambitious project – the Aureus
                            Metaverse. This
                            groundbreaking venture is more than just a digital space; it's a thriving ecosystem where
                            culture, economy, and
                            entertainment from diverse communities find a common ground for growth and interaction.
                        </p>

                        <p>
                            In the coming days, we are excited to unveil a series of innovative programs aimed at
                            bolstering the Aureus
                            Metaverse. These initiatives are designed to catalyze the growth of our digital country,
                            fostering an
                            environment where creativity, commerce, and entertainment coalesce seamlessly.
                        </p>

                        <p>
                            Join us on this exhilarating journey as we redefine the possibilities of the digital world,
                            one innovative step
                            at a time.
                        </p>

                    </div>
                </div>
            </section>
            <WebsiteFooter/>
        </>

    );
}


