import * as React from 'react';
import {Card, Box, CardActions, CardHeader, CardContent, Typography, Button} from '@mui/material'
import './style.scss'


export function AdminDashboardPage() {
    const bull = 0;
    return (
        <>
            <div className="AdminDashboardPage">
                <div className="card">
                    <div className="card-header">
                        <h4>
                            Coming Soon!
                        </h4>
                        <p>
                            This section will be launched in few days!
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

