import axios from "axios";
import {environment} from "../../_env";
import {AxiosInspector} from "../inspector";

export class AdminTransactionRepo {
    static route = `${environment.apiEndpoint}/admin/transaction`

    static get(txh) {
        AxiosInspector();
        return axios.get(`${this.route}/${txh}`)
    }

    static adminSearch() {
        AxiosInspector();
        return axios.get(`${this.route}/admin-search`)
    }

    static insert(params) {
        AxiosInspector();
        return axios.post(this.route, params)
    }

}
