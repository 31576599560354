import React from "react";
import './icons.scss'
import {ColorEnum} from "../../enums";

export const IconEnum = {
    pdf: "pdf",
    medium: "medium",
    more_v: "more_v",
    chevronDown: "chevronDown",
    chevronUp: "chevronUp",
    chainLogo_BNB: "chainLogo_BNB",
    chainLogo_BNB_Test: "chainLogo_BNB_Test",
    chainLogo_Ethereum: "chainLogo_Ethereum",

    walletIcon: {
        metaMask: "walletIcon_MetaMask",
        trustWallet: "walletIcon_TrustWallet",
        connectWallet: "walletIcon_ConnectWallet",
    }
};

export const IconSizeEnum = {
    xxs: "xxs",
    xs: "xs",
    s: "s",
    m: "m",
    l: "l",
    xl: "xl",
    xxl: "xxl",
};

/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export class Icon extends React.Component {

    chooseIcon(icon) {
        switch (icon) {
            case IconEnum.pdf:
                return <svg
                    className="icon-outline"
                    xmlns="http://www.w3.org/2000/svg"
                    width="5in"
                    height="5in"
                    version="1.1"
                    viewBox="0 0 20 20">
                    <g>

                        <polyline
                            points="3.08436,7.79015 3.08436,0.57931 14.41219,0.57931 19.40178,5.68341 19.40178,19.43954 3.08436,19.43954 3.08436,16.31917 "/>
                        <rect x="0.53241" y="7.79015" width="16.74879" height="8.52902"/>
                        <polyline points="14.41219,0.57931 14.41219,5.65228 19.37135,5.65228 "/>
                        <path d="M15.64472 9.65402l-2.98428 0 0 4.72774m0 -2.42015l2.59815 0"/>
                        <path
                            d="M7.30395 14.33151l1.56703 0c0.25659,0 0.52194,-0.0193 0.7718,-0.08053 0.18293,-0.04483 0.36471,-0.11351 0.52196,-0.21882 0.15585,-0.10437 0.28628,-0.24491 0.39574,-0.39647 0.14296,-0.19795 0.24081,-0.43926 0.31081,-0.67202 0.09408,-0.31283 0.12613,-0.64863 0.12613,-0.97432 0,-0.3868 -0.0497,-0.78055 -0.18652,-1.14399 -0.11295,-0.30004 -0.28299,-0.55901 -0.52724,-0.7667 -0.18354,-0.15606 -0.3985,-0.25344 -0.63198,-0.30781 -0.26426,-0.06154 -0.59689,-0.06657 -0.86731,-0.06657l-1.48039 0 0 4.62723z"/>
                        <path
                            d="M2.319 12.16144l1.72235 0c0.39215,0 1.01836,-0.04552 1.3054,-0.35813 0.2373,-0.25844 0.34021,-0.56217 0.34021,-0.91234 0,-0.19984 -0.03716,-0.39271 -0.12531,-0.57256 -0.07381,-0.1506 -0.17354,-0.28483 -0.31242,-0.38076 -0.15184,-0.10489 -0.33887,-0.16089 -0.51892,-0.19181 -0.23894,-0.04104 -0.5089,-0.04589 -0.75084,-0.04589l-1.66046 0 0 4.63591"/>
                    </g>
                </svg>;
            case IconEnum.medium:
                return <svg
                    className="icon-fill"
                    xmlns="http://www.w3.org/2000/svg"
                    width="5in"
                    height="5in"
                    version="1.1"
                    viewBox="0 -55 256 256">
                    <g>
                        <path
                            d="M72.2009141,1.42108547e-14 C112.076502,1.42108547e-14 144.399375,32.5485469 144.399375,72.6964154 C144.399375,112.844284 112.074049,145.390378 72.2009141,145.390378 C32.327779,145.390378 0,112.844284 0,72.6964154 C0,32.5485469 32.325326,1.42108547e-14 72.2009141,1.42108547e-14 Z M187.500628,4.25836743 C207.438422,4.25836743 223.601085,34.8960455 223.601085,72.6964154 L223.603538,72.6964154 C223.603538,110.486973 207.440875,141.134463 187.503081,141.134463 C167.565287,141.134463 151.402624,110.486973 151.402624,72.6964154 C151.402624,34.9058574 167.562834,4.25836743 187.500628,4.25836743 Z M243.303393,11.3867175 C250.314,11.3867175 256,38.835526 256,72.6964154 C256,106.547493 250.316453,134.006113 243.303393,134.006113 C236.290333,134.006113 230.609239,106.554852 230.609239,72.6964154 C230.609239,38.837979 236.292786,11.3867175 243.303393,11.3867175 Z"
                            fill="#000000"/>
                    </g>
                </svg>
            case IconEnum.more_v:
                return <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon-outline"
                    width="5in"
                    height="5in"
                    version="1.1"
                    viewBox="0 0 20 20">
                    <g>
                        <path
                            d="M7.5981 3.1467c0,-1.3265 1.0754,-2.4019 2.4019,-2.4019 1.3265,0 2.4019,1.0754 2.4019,2.4019 0,1.3265 -1.0754,2.4019 -2.4019,2.4019 -1.3265,0 -2.4019,-1.0754 -2.4019,-2.4019z"/>
                        <path
                            d="M7.5981 16.8533c0,-1.3265 1.0754,-2.4019 2.4019,-2.4019 1.3265,0 2.4019,1.0754 2.4019,2.4019 0,1.3265 -1.0754,2.4019 -2.4019,2.4019 -1.3265,0 -2.4019,-1.0754 -2.4019,-2.4019z"/>
                        <path
                            d="M7.5981 10c0,-1.3265 1.0754,-2.4019 2.4019,-2.4019 1.3265,0 2.4019,1.0754 2.4019,2.4019 0,1.3265 -1.0754,2.4019 -2.4019,2.4019 -1.3265,0 -2.4019,-1.0754 -2.4019,-2.4019z"/>

                    </g>
                </svg>

            case IconEnum.chevronDown:
                return <svg
                    className="icon-outline"
                    xmlns="http://www.w3.org/2000/svg"
                    width="5in"
                    height="5in"
                    version="1.1"
                    viewBox="0 0 20 20">
                    <g>
                        <polyline class="fil0 str0" points="13.5678,8.2118 10,11.7882 6.4323,8.2118 "/>
                    </g>
                </svg>
            case IconEnum.chevronUp:
                return <svg
                    className="icon-outline"
                    xmlns="http://www.w3.org/2000/svg"
                    width="5in"
                    height="5in"
                    version="1.1"
                    viewBox="0 0 20 20">
                    <g>
                        <polyline class="fil0 str0" points="6.4323,11.7882 10,8.2117 13.5678,11.7881 "/>
                    </g>
                </svg>


            case IconEnum.chainLogo_BNB: {
                return <svg version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 2496 2496"
                            style={{"enable-background": "new 0 0 2496 2496"}}>
                    <g>
                        <path style={{'fill-rule': 'evenodd', 'clip-rule': 'evenodd', 'fill': '#F0B90B',}} d="M1248,0c689.3,0,1248,558.7,1248,1248s-558.7,1248-1248,1248
		S0,1937.3,0,1248S558.7,0,1248,0L1248,0z"/>
                        <path style={{"fill": "#FFFFFF"}} d="M685.9,1248l0.9,330l280.4,165v193.2l-444.5-260.7v-524L685.9,1248L685.9,1248z M685.9,918v192.3
		l-163.3-96.6V821.4l163.3-96.6l164.1,96.6L685.9,918L685.9,918z M1084.3,821.4l163.3-96.6l164.1,96.6L1247.6,918L1084.3,821.4
		L1084.3,821.4z"/>
                        <path style={{"fill": "#FFFFFF"}} d="M803.9,1509.6v-193.2l163.3,96.6v192.3L803.9,1509.6L803.9,1509.6z M1084.3,1812.2l163.3,96.6
		l164.1-96.6v192.3l-164.1,96.6l-163.3-96.6V1812.2L1084.3,1812.2z M1645.9,821.4l163.3-96.6l164.1,96.6v192.3l-164.1,96.6V918
		L1645.9,821.4L1645.9,821.4L1645.9,821.4z M1809.2,1578l0.9-330l163.3-96.6v524l-444.5,260.7v-193.2L1809.2,1578L1809.2,1578
		L1809.2,1578z"/>
                        <polygon style={{"fill": "#FFFFFF"}}
                                 points="1692.1,1509.6 1528.8,1605.3 1528.8,1413 1692.1,1316.4 1692.1,1509.6 	"/>
                        <path style={{"fill": "#FFFFFF"}} d="M1692.1,986.4l0.9,193.2l-281.2,165v330.8l-163.3,95.7l-163.3-95.7v-330.8l-281.2-165V986.4
		L968,889.8l279.5,165.8l281.2-165.8l164.1,96.6H1692.1L1692.1,986.4z M803.9,656.5l443.7-261.6l444.5,261.6l-163.3,96.6
		l-281.2-165.8L967.2,753.1L803.9,656.5L803.9,656.5z"/>
                    </g>
                </svg>
            }
            case IconEnum.chainLogo_BNB_Test: {
                return <svg version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 2496 2496"
                            style={{"enable-background": "new 0 0 2496 2496"}}>
                    <g>
                        <path style={{'fill-rule': 'evenodd', 'clip-rule': 'evenodd', 'fill': '#AAAAAA',}} d="M1248,0c689.3,0,1248,558.7,1248,1248s-558.7,1248-1248,1248
		S0,1937.3,0,1248S558.7,0,1248,0L1248,0z"/>
                        <path style={{"fill": "#FFFFFF"}} d="M685.9,1248l0.9,330l280.4,165v193.2l-444.5-260.7v-524L685.9,1248L685.9,1248z M685.9,918v192.3
		l-163.3-96.6V821.4l163.3-96.6l164.1,96.6L685.9,918L685.9,918z M1084.3,821.4l163.3-96.6l164.1,96.6L1247.6,918L1084.3,821.4
		L1084.3,821.4z"/>
                        <path style={{"fill": "#FFFFFF"}} d="M803.9,1509.6v-193.2l163.3,96.6v192.3L803.9,1509.6L803.9,1509.6z M1084.3,1812.2l163.3,96.6
		l164.1-96.6v192.3l-164.1,96.6l-163.3-96.6V1812.2L1084.3,1812.2z M1645.9,821.4l163.3-96.6l164.1,96.6v192.3l-164.1,96.6V918
		L1645.9,821.4L1645.9,821.4L1645.9,821.4z M1809.2,1578l0.9-330l163.3-96.6v524l-444.5,260.7v-193.2L1809.2,1578L1809.2,1578
		L1809.2,1578z"/>
                        <polygon style={{"fill": "#FFFFFF"}}
                                 points="1692.1,1509.6 1528.8,1605.3 1528.8,1413 1692.1,1316.4 1692.1,1509.6 	"/>
                        <path style={{"fill": "#FFFFFF"}} d="M1692.1,986.4l0.9,193.2l-281.2,165v330.8l-163.3,95.7l-163.3-95.7v-330.8l-281.2-165V986.4
		L968,889.8l279.5,165.8l281.2-165.8l164.1,96.6H1692.1L1692.1,986.4z M803.9,656.5l443.7-261.6l444.5,261.6l-163.3,96.6
		l-281.2-165.8L967.2,753.1L803.9,656.5L803.9,656.5z"/>
                    </g>
                </svg>
            }
            case IconEnum.chainLogo_Ethereum: {
                return <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    version="1.1"
                    shape-rendering="geometricPrecision"
                    text-rendering="geometricPrecision"
                    image-rendering="optimizeQuality"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    viewBox="0 0 784.37 1277.39"
                >
                    <g id="Layer_x0020_1">
                        <metadata id="CorelCorpID_0Corel-Layer"/>
                        <g id="_1421394342400">
                            <g>
                                <polygon fill="#343434" fill-rule="nonzero"
                                         points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54 "/>
                                <polygon fill="#8C8C8C" fill-rule="nonzero"
                                         points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33 "/>
                                <polygon fill="#3C3C3B" fill-rule="nonzero"
                                         points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89 "/>
                                <polygon fill="#8C8C8C" fill-rule="nonzero"
                                         points="392.07,1277.38 392.07,956.52 -0,724.89 "/>
                                <polygon fill="#141414" fill-rule="nonzero"
                                         points="392.07,882.29 784.13,650.54 392.07,472.33 "/>
                                <polygon fill="#393939" fill-rule="nonzero"
                                         points="0,650.54 392.07,882.29 392.07,472.33 "/>
                            </g>
                        </g>
                    </g>
                </svg>
            }


            case IconEnum.walletIcon.metaMask: {
                return <svg
                    width="256px"
                    height="240px"
                    viewBox="0 0 256 240"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid">
                    <title>MetaMask</title>
                    <g>
                        <polygon fill="#E17726"
                                 points="250.066018 -8.89651791e-15 140.218553 81.2793133 160.645643 33.3787726"></polygon>
                        <polygon fill="#E27625"
                                 points="6.19062016 0.0955267053 95.3715526 33.38465 114.767923 81.9132784"></polygon>
                        <polygon fill="#E27625"
                                 points="205.859986 172.858026 254.410647 173.782023 237.442988 231.424252 178.200429 215.112736"></polygon>
                        <polygon fill="#E27625"
                                 points="50.1391619 172.857971 77.6964289 215.11288 18.5530579 231.425317 1.68846828 173.782036"></polygon>
                        <polygon fill="#E27625"
                                 points="112.130724 69.5516472 114.115388 133.635085 54.744344 130.933905 71.6319541 105.456448 71.8456974 105.210668"></polygon>
                        <polygon fill="#E27625"
                                 points="143.254237 68.8369186 184.153999 105.213392 184.365514 105.45719 201.253537 130.934656 141.89632 133.635226"></polygon>
                        <polygon fill="#E27625"
                                 points="79.4347776 173.043957 111.853145 198.302774 74.1951401 216.484384"></polygon>
                        <polygon fill="#E27625"
                                 points="176.57078 173.040009 181.701672 216.484523 144.149363 198.301203"></polygon>
                        <polygon fill="#D5BFB2"
                                 points="144.977922 195.921642 183.084879 214.373531 147.637779 231.220354 148.005818 220.085704"></polygon>
                        <polygon fill="#D5BFB2"
                                 points="111.01133 195.929982 108.102093 219.90359 108.340838 231.207237 72.8105145 214.373665"></polygon>
                        <polygon fill="#233447"
                                 points="100.007166 141.998856 109.965172 162.926822 76.0615945 152.995277"></polygon>
                        <polygon fill="#233447"
                                 points="155.991579 142.000941 180.049716 152.994594 146.03608 162.923638"></polygon>
                        <polygon fill="#CC6228"
                                 points="82.0263962 172.830401 76.5459821 217.870023 47.1731221 173.814952"></polygon>
                        <polygon fill="#CC6228"
                                 points="173.976111 172.8305 208.830462 173.815081 179.347016 217.871514"></polygon>
                        <polygon fill="#CC6228"
                                 points="202.112267 128.387342 176.746779 154.238424 157.190334 145.301352 147.82685 164.985265 141.688645 131.136429"></polygon>
                        <polygon fill="#CC6228"
                                 points="53.8753865 128.386879 114.309585 131.136429 108.17138 164.985265 98.8061425 145.303856 79.3525107 154.238823"></polygon>
                        <polygon fill="#E27525"
                                 points="52.165606 123.082486 80.8639084 152.203386 81.8584812 180.952278"></polygon>
                        <polygon fill="#E27525"
                                 points="203.863346 123.029784 174.117491 181.003017 175.237428 152.202737"></polygon>
                        <polygon fill="#E27525"
                                 points="112.906762 124.855691 114.061658 132.125682 116.915771 150.236518 115.080954 205.861884 106.405804 161.177486 106.402953 160.71542"></polygon>
                        <polygon fill="#E27525"
                                 points="143.077997 124.755417 149.599051 160.715451 149.596194 161.177486 140.899333 205.973714 140.55515 194.76913 139.198167 149.907127"></polygon>
                        <polygon fill="#F5841F"
                                 points="177.788479 151.045975 176.81718 176.023897 146.543342 199.61119 140.4233 195.28712 147.283427 159.951634"></polygon>
                        <polygon fill="#F5841F"
                                 points="78.3167053 151.046455 108.716464 159.952427 115.576437 195.28712 109.456385 199.611197 79.1807344 176.021881"></polygon>
                        <polygon fill="#C0AC9D"
                                 points="67.0180978 208.857597 105.750143 227.209502 105.586194 219.372868 108.826835 216.528328 147.160694 216.528328 150.518758 219.363342 150.271375 227.194477 188.757733 208.903978 170.030292 224.379509 147.384611 239.933315 108.516484 239.933315 85.8855503 224.315941"></polygon>
                        <polygon fill="#161616"
                                 points="142.203502 193.479367 147.679764 197.347701 150.888964 222.952494 146.244706 219.030957 109.769299 219.030957 105.213447 223.031398 108.317268 197.349663 113.795429 193.479367"></polygon>
                        <polygon fill="#763E1A"
                                 points="242.814251 2.24978946 256 41.8072765 247.765337 81.803692 253.629038 86.3274221 245.694407 92.3812097 251.657525 96.9865879 243.761206 104.178247 248.609106 107.688972 235.743366 122.714803 182.973386 107.350364 182.516079 107.105244 144.488982 75.0267414"></polygon>
                        <polygon fill="#763E1A"
                                 points="13.1860054 2.24978557 111.51151 75.0267402 73.4844118 107.105244 73.0271023 107.350365 20.2567388 122.714804 7.39121291 107.688927 12.2352706 104.180751 4.34251001 96.9865923 10.2945566 92.3862179 2.24133703 86.315099 8.32629691 81.7886671 -8.89651791e-15 41.8087534"></polygon>
                        <polygon fill="#F5841F"
                                 points="180.391638 103.990363 236.304873 120.269177 254.470245 176.254719 206.546445 176.25462 173.525532 176.671282 197.539657 129.863284"></polygon>
                        <polygon fill="#F5841F"
                                 points="75.6080363 103.990376 58.4568191 129.863284 82.4741865 176.671282 49.4693913 176.254719 1.63053271 176.254719 19.6938968 120.269548"></polygon>
                        <polygon fill="#F5841F"
                                 points="163.383898 33.1117385 147.744691 75.3505047 144.425852 132.411352 143.155934 150.295986 143.055195 195.983514 112.943788 195.983514 112.846176 150.381702 111.572114 132.395585 108.251786 75.3505047 92.6150854 33.1117385"></polygon>
                    </g>
                </svg>
            }
            case IconEnum.walletIcon.trustWallet: {
                return <svg
                    width="800px"
                    height="800px"
                    viewBox="0 0 192 192"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none">
                    <path
                        stroke="#3B99FC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="10"
                        stroke-width="12"
                        d="M95.958 22C121.031 42.867 149.785 42 158 42c-1.797 118.676-15 95-62.042 128C49 137 35.798 160.676 34 42c8.13 0 36.883.867 61.958-20Z"/>
                </svg>
            }
            case IconEnum.walletIcon.connectWallet: {
                return <svg
                    version="1.1"
                    baseProfile="basic"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 387.6 237.6">
                    <path fill="#3B99FC" d="M79.4,46.4
	c63.2-61.9,165.7-61.9,228.9,0l7.6,7.4c3.2,3.1,3.2,8.1,0,11.2l-26,25.5c-1.6,1.5-4.1,1.5-5.7,0l-10.5-10.3
	c-44.1-43.2-115.6-43.2-159.7,0l-11.2,11c-1.6,1.5-4.1,1.5-5.7,0L71,65.8c-3.2-3.1-3.2-8.1,0-11.2L79.4,46.4z M362.1,99.1l23.2,22.7
	c3.2,3.1,3.2,8.1,0,11.2L280.8,235.3c-3.2,3.1-8.3,3.1-11.4,0c0,0,0,0,0,0l-74.1-72.6c-0.8-0.8-2.1-0.8-2.9,0c0,0,0,0,0,0
	l-74.1,72.6c-3.2,3.1-8.3,3.1-11.4,0c0,0,0,0,0,0L2.4,133c-3.2-3.1-3.2-8.1,0-11.2l23.2-22.7c3.2-3.1,8.3-3.1,11.4,0l74.1,72.6
	c0.8,0.8,2.1,0.8,2.9,0c0,0,0,0,0,0l74.1-72.6c3.2-3.1,8.3-3.1,11.4,0c0,0,0,0,0,0l74.1,72.6c0.8,0.8,2.1,0.8,2.9,0l74.1-72.6
	C353.8,96,358.9,96,362.1,99.1z"/>
                </svg>
            }
            default:
                return '';
        }
    }

    constructor(props) {
        super(props);

    }

    render() {
        return <span
            Color={this.props.color || ''}
            Size={this.props.size || ''}
            className="svg-icon">
            {this.chooseIcon(this.props.name)}
        </span>
    }


}

Icon.propTypes = {
    name: IconEnum,
    color: ColorEnum,
    size: IconSizeEnum,
}

