import {LoadingButton} from "@mui/lab";
import * as React from "react";
import {ConnectWallet_IncreaseCreditDialog} from "./connectWallet_increaseCreditDialog";
import PropTypes from 'prop-types';

export function ConnectWallet_TransactionButton(props) {

    const [state, setState] = React.useState({
        loading: false,
        dialogOpen: false
    })

    return (
        <>
            <LoadingButton
                disabled={state.loading}
                loading={state.loading}
                loadingPosition='center'
                variant="contained" className="primary-btn"
                onClick={() => {
                    state.loading = true;
                    state.dialogOpen = true;
                    setState({...state, state});
                }}>
                Increase Credit
            </LoadingButton>
            {
                state.dialogOpen ?
                    <ConnectWallet_IncreaseCreditDialog onClose={() => {
                        state.loading = false;
                        state.dialogOpen = false;
                        setState({...state, state});
                        if (props.onClose) {
                            props.onClose()
                        }
                    }}/>
                    : ''
            }
        </>
    )
}

