import React from "react";
import logo from "assets/logo/logo2.png";
import map from "assets/img/map.png";
import {Link} from 'react-router-dom'
import {environment} from 'core/_env.jsx'
import {HashInputDialog} from "../../../core/components/HashControllerDialog";


export function WebsiteFooter() {
    const [isHashInputDialogOpen, setHashInputDialogOpen] = React.useState(false);
    const newYear = new Date().getFullYear().toString();
    return (
        <div className="siteFooterComponent">
            <div className="master-content-frame">
                <div className="content">
                    <div className="logo">
                        <a href="./">
                            <img src={logo}/>
                            <div className="title">
                                TrumpLand
                            </div>
                        </a>
                    </div>
                    <div className="columns">


                        <div>


                            <Link to="/contact-us">
                                Contact Us
                            </Link>
                            <Link to="/about-us">
                                About Us
                            </Link>
                            <Link to="/white-paper">
                                White Paper
                            </Link>

                        </div>
                        <div>
                            {/* <a href={environment.socialNetworkLink.telegram} target="_blank">
                                Telegram
                            </a>*/}
                            <a href={environment.socialNetworkLink.twitter} target="_blank">
                                Twitter
                            </a>
                            {/*<a target="_blank">
                                Instagram
                            </a>*/}
                            <a href={environment.socialNetworkLink.mediumWhitePaper} target="_blank">
                                Medium (White Paper)
                            </a>

                        </div>
                        <div>

                          {/*  <a onClick={() => {
                                setHashInputDialogOpen(true)
                            }}>
                                Check Txh
                            </a>*/}

                            <Link to='/admin/login'>
                                Admin Panel
                            </Link>
                            <a href="https://wmt.trumpland.io/" target='_blank'>
                                WMT
                            </a>
                            <a href="https://bonds.trumpland.io/" target='_blank'>
                                Bonds
                            </a>
                            {/*{isHashInputDialogOpen ? <HashInputDialog onClose={(e) => {
                                setHashInputDialogOpen(false);
                            }}/> : ''}*/}

                        </div>
                    </div>
                </div>

            </div>
            <div class="box-2">
                <div className="master-content-frame">
                    <div className="content ">
                        <div className="columns">

                        <div>

                                <a href="https://www.trumptowerny.com/" target='_blank'>
                                    Trump Tower
                                </a>
                                <a href="https://www.trumpstore.com/" target='_blank'>
                                    Trump Store
                                </a>
                                <a href="https://www.trumpgolf.com/" target='_blank'>
                                    Trump Golf
                                </a>

                                <a href="https://www.trumpinternationalrealty.com/" target='_blank'>
                                    Trump International Realty
                                </a>
                                <a href="https://721fifth.com/" target='_blank'>
                                    721 Fifth Avenue Trump Tower Condominiums
                                </a>

                            </div>

                            <div className='map-box'>
                                <div className="address">
                                    725 5th Ave, New York, NY 10022, USA
                                    <br/>
                                    <small>
                                    <small>
                                        ©2024 TRUMP TOWER NEW YORK
                                    </small>
                                    </small>
                                </div>
                                <div>

                                </div>

                                <div class="frame">
                                    <a href='https://www.bing.com/maps?osid=0ede819e-ffc9-4147-ac4a-f7e8b7a63ab3&cp=40.76234~-73.975559&lvl=18.67&pi=0&imgid=fb32a39d-67a0-4694-90a6-9c5e768bb4c9&v=2&sV=2&form=S00027'
                                       target='_blank'>
                                        <img src={map}/>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="master-content-frame">
                    <div className="content">
                        <div>
                            © {newYear} TrumpLand. All rights reserved
                        </div>
                        <div className="yellow-color">
                            Contract:
                            <strong>
                                {environment.TLT.contract}
                            </strong>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}
