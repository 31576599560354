import Image from 'assets/img/connectWallet.jpg'
import './style.scss'
import {WalletConnectButton, WalletTransactionButton} from "../../../core/components/wallet";
import {Buy_TLT_Dialog} from "../../../core/components";
import {default as React, useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";

export function LandingPage_ConnectWalletSection() {
    const [state, setState] = useState({
        dialogOpen: false,
        wallet: window.wallet
    });

    useEffect(() => {
        console.log("call");
        setState({...state, state})
        window.wallet.observable.subscribe((res) => {
            setState({...state, state})
        })
    }, [])


    const createDesign = () => {
        if (state.wallet.isConnected) {
            return <LoadingButton
                loading={state.loading}
                loadingPosition='center'
                variant="contained"
                className="primary-btn"
                onClick={() => {
                    state.dialogOpen = true;
                    setState({...state, state});
                }}
            >
                Buy TLT
            </LoadingButton>
        } else {
            return <WalletConnectButton/>
        }
    }
    return (
        <section className='LandingPage_ConnectWallet'>
            <div className="master-content-frame">
                <div className="frame">
                    <div className="left">
                        <img src={Image}/>

                    </div>
                    <div className="right">
                        <h2>
                            Connect Wallet (Beta)
                        </h2>

                        <p>
                            Direct transaction without registration information just by pressing a button
                        </p>
                        <p>
                            New feature of buying TLT in the Trumpland Metaverse project
                        </p>
                        <div className="btn">
                            {createDesign()}
                        </div>
                        <Buy_TLT_Dialog open={state.dialogOpen} onClose={() => {
                            state.dialogOpen = false
                            setState({...state, state})
                        }}/>
                        {/*<Button variant="contained" className="primary-btn contained">
                            Connect Wallet
                        </Button>*/}
                    </div>
                </div>

            </div>
        </section>
    )
}
