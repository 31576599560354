import * as React from 'react';

import './style.scss'
import {AdminTransactionRepo} from "../../../../core/repository/admin";
import {StandardHelper, ToastHelper} from "../../../../core/helper";
import {Icon, IconEnum} from "../../../../core/components/icons/icons";
import {ColorEnum} from "../../../../core/enums";
import {AdminPanel_ActionBar} from "../components";
import {ConnectWallet_TransactionButton} from "../../../../core/components/connectWallet";


export function AdminTransactionHistoryPage() {
    const [state, setState] = React.useState({
        transactionList: []
    });
    React.useEffect(() => {
        fetch();
    }, [])

    const fetch = () => {
        AdminTransactionRepo.adminSearch()
            .then((res) => {
                    try {
                        state.transactionList = res.data.transactionList.map((item) => {
                            return item
                        });
                        setState({...state, state});
                    } catch (e) {
                        ToastHelper.showError()
                    }
                }
            ).catch((err) => {
            ToastHelper.showError()
        })
    }


    const createListDesign = () => {
        return state.transactionList.map((row, index) => {
            return <tr className={index % 2 === 1 ? 'second' : ''}>
                <td>
                    {index + 1}
                </td>
                <td>
                    {StandardHelper.TLT_number(row.amount)}
                </td>
                <td>
                    {row.dateTime}
                </td>
                <td>
                    <div className="svg-icon-holder">
                        {(() => {
                            switch (row.transactionType) {
                                case 'pay': {
                                    return <Icon
                                        color={ColorEnum.red}
                                        name={IconEnum.chevronUp}/>
                                }
                                case 'receive': {
                                    return <Icon
                                        color={ColorEnum.green}
                                        name={IconEnum.chevronDown}/>
                                }
                            }
                        })()}
                        {row.transactionType}
                    </div>
                </td>
                <td>
                    {row.transformType}
                </td>
            </tr>
        })
    }

    return (
        <>
            <div className="AdminTransactionHistoryPage">
                <AdminPanel_ActionBar>
                    <div className="side">

                    </div>
                    <div className="side">
                        <ConnectWallet_TransactionButton
                            onClose={() => {
                                fetch();
                            }}
                        />
                    </div>
                </AdminPanel_ActionBar>

                <div className="inner-content">
                    <div className="card">
                        <div className="card-header">
                            <h4>
                                Transaction History
                            </h4>
                            <p>
                                You can see all your transactions in this section.
                            </p>
                        </div>
                    </div>
                    <div className="card no-padding">
                        <div className="card-content no-padding">
                            <table className='table'>
                                <thead>
                                <tr>
                                    <th>
                                        No.
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Date/Time
                                    </th>
                                    <th>
                                        Transaction Type
                                    </th>
                                    <th>
                                        Causes
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {createListDesign()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

