import {WebsiteFooter, WebsiteHeader} from "../layout";
import './style.scss'
import {useParams} from "react-router-dom";

export function ConfirmRegistrationPage() {
    const routerParam = useParams();

    const renderResult = () => {
        switch (routerParam.fallowCode) {
            case 'error': {
                return <>
                    <p className="red-color">
                        Unfortunately we cant confirm your request at this time.
                    </p>
                    <p>
                        We are hardly work to improve our website to service you as soon as possible.
                    </p>
                </>
            }
            case 'error-not-found': {
                return <>
                    <p className="red-color">
                        No transaction was found with the entered information.
                    </p>
                    <p>
                        Please check your transaction information carefully and register again.
                    </p>
                </>
            }
            default: {
                return <>
                    <p>
                        You have successfully confirmed your request to buy TrumpLand crypto. A confirmation
                        e-mail
                        with further details
                        will be sent shortly.
                    </p>

                    Keep in mind that by purchasing any amount of Trumpland tokens with your.
                    <strong className="yellow-color">
                        {' '}referral code
                    </strong>
                    ,
                    <strong className="yellow-color">
                        {' '} 30%{' '}
                    </strong>
                    of the purchase amount will be credited to your referral account in the form of BNB.

                    <p className="success">
                        Your referral code is:
                        <strong>
                            {' '} {routerParam.fallowCode}
                        </strong>
                    </p>
                </>
            }
        }
    }
    return (
        <>
            <WebsiteHeader/>
            <section className='ConfirmRegistrationPage'>
                <div className="master-content-frame">
                    <div className="content">

                        <h1>
                            Thank you for your registration.
                        </h1>

                        {renderResult()}

                    </div>
                </div>
            </section>
            <WebsiteFooter/>
        </>
    )
}
