import * as React from 'react';
import {Dialog, Checkbox, Button} from '@mui/material';
import {LoadingButton} from "@mui/lab";
import './style.scss'
export function PolicyConfirmationDialog(props) {
    const {onClose} = props;

    const [checked, setChecked] = React.useState(false);


    return (
        <Dialog open={true}>
            <div className="dialog-header">
                Trumpland Project Administrator Policy Confirmation
            </div>

            <div className="dialog-content ">
                <div className="PolicyConfirmationDialog">
                    <h2>
                        Trumpland Project Administrator Policy
                    </h2>
                    <ol>

                        <li><h4>Identity Verification:</h4>
                            <p>
                                All administrators are required to provide comprehensive identity
                                information. This is essential to ascertain and confirm your status as an administrator
                                within the Trumpland project.</p>
                        </li>

                        <li><h4>Legal Compliance:</h4>
                            <p>
                                Administrators must disclose if they are listed on the Interpol police
                                list, global sanctions lists, or have any affiliations with terrorist groups. Failure to
                                honestly disclose such information will result in termination of cooperation, alongside
                                applicable fines and damages, as per our project rules.</p>
                        </li>

                        <li><h4>Responsibility and Performance:</h4>
                            <p>
                                Administrators are expected to commit to their duties and
                                contribute to the growth of the Trumpland community. Inability to uphold this
                                responsibility
                                may lead to the revocation of administrative status, relegating the individual to a
                                customer
                                role within the project.</p>
                        </li>

                        <li><h4>
                            Incentives for Community Growth:
                        </h4>
                            <p>
                                Administrators who successfully expand the community in
                                alignment with our objectives will be eligible for special rewards and recognition.
                            </p>
                        </li>

                        <li><h4>Consequences for Underperformance:</h4>
                            <p>
                                Administrators failing to meet set goals will be subject
                                to a penalty, involving a 10% deduction from their income and admin package. The
                                remaining
                                90% will be returned to the administrator.
                            </p>
                        </li>

                        <li><h4>Market Strategy and Brand Protection:</h4>
                            <p>
                                The Trumpland project will generally not intervene in
                                an administrator's market strategy unless it is deemed harmful to the project's brand.
                                In
                                such cases, actions will be taken in accordance with the regulations outlined in
                                paragraph
                            </p>
                        </li>

                        <li><h4>Feedback and Suggestions:</h4>
                            <p>
                                While we encourage administrators to provide constructive
                                suggestions, this does not guarantee the implementation of such ideas.
                            </p>
                        </li>

                        <li><h4>Compensation and Withdrawals:</h4>
                            <p>
                                Administrator compensation is calculated monthly and displayed
                                as TLT on your dashboard. Administrators have the option to withdraw their earnings in
                                the
                                available currencies.
                            </p>
                        </li>

                        <li><h4>Exclusive Rights:</h4>
                            <p>
                                Administrators are exclusively permitted to purchase metaverse cities and
                                assume roles as metaverse within these cities.
                            </p>
                        </li>

                        <li><h4>Communication Protocol:</h4>
                            <p>
                                Communication with administrators is primarily conducted through
                                tickets. Administrators are advised to refrain from using email for official
                                communications.
                            </p>
                        </li>

                        <li><h4>Attention to Official Communications:</h4>
                            <p>
                                Administrators should closely monitor and respond to
                                messages received via their ticket on the platform.</p>
                        </li>

                        <li><h4>Marketing Plan Participation:</h4>
                            <p>
                                Administrators have the same access as others to all marketing
                                plans of the project.</p>
                        </li>

                        <li><h4>New Plan Initiatives:</h4>
                            <p>
                                If an administrator wishes to initiate a new plan, they must bear the
                                cost independently. This cost is not covered by any previous plans.</p>
                        </li>

                        <li><h4>Plan Change Policy:</h4>
                            <p>
                                Administrators must wait a month to change their plan. Mid-month changes
                                are not permitted.</p>
                        </li>

                        <li><h4>NFT Rewards:</h4>
                            <p>
                                Administrators will receive an NFT corresponding to the purchased plans.
                                Details on the utilization of these NFTs within the project will be provided
                                subsequently.
                            </p>
                        </li>

                    </ol>

                    <div className="confirmation-checkbox">
                        <Checkbox
                            required
                            className='primary-check-box'
                            checked={checked}
                            onChange={(e, value) => {
                                setChecked(value)
                            }}
                            defaultChecked>

                        </Checkbox>
                        <div className='check-box-text' onClick={() => {
                            setChecked(!checked)
                        }}>
                            By ticking, you are confirming that you have read, understood and agree to Trumpland Project
                            Administrator terms and conditions.
                        </div>
                    </div>

                </div>
            </div>

            <div className="dialog-footer">
                <div>
                    <Button
                        variant="contained"
                        disabled={!checked}
                        className="primary-btn"
                        onClick={() => {
                            onClose(true)
                        }}>
                        Confirm
                    </Button>
                </div>
                <div>
                    <Button variant="contained" className="white-btn contained" onClick={() => {
                        onClose()
                    }}>
                        Close
                    </Button>
                </div>
            </div>
        </Dialog>
    )
        ;
}
