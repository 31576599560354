import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Outlet} from "react-router-dom";
import './core/style/standard.scss';

import {ScrollRestoration} from "react-router-dom";
import React from "react";
import {WalletHook} from "core/components/index";

export default function App() {
    return (
        <>
            <WalletHook/>
            <Outlet/>
            <ScrollRestoration/>
        </>
    )
}



