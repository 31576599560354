import axios from "axios";
import {AxiosInspector} from "../inspector";
import {environment} from "../../_env";

export class SystemManager_BuyRequestRepo {
    static route = `${environment.apiEndpoint}/system-manager/buy-request`

    static all(param: {
        count: number,
    }) {
        AxiosInspector();
        return axios.get(this.route, {
            params: param
        })
    }

}



