import * as React from 'react';
import './style.scss'
import {TextField} from "@mui/material";
import {ToastHelper, ValidationHelper} from "../../../../core/helper";
import {TransactionRepo,TransactionGetModel} from "../../../../core/repository";
import {LoadingButton} from "@mui/lab";
import {Routes} from "../../../../core/router";
import {useNavigate} from "react-router-dom";


export function SystemManage_CheckTxh_Page() {
    const [state, setState] = React.useState({
        loading: false,

        searchTxh: {
            value: '',
            hasError: false,
            isDirty: false
        },
    });

    const navigator = useNavigate();
    const txhValidation = () => {
        state.searchTxh.isDirty = true;
        state.searchTxh.hasError = ValidationHelper.checkTxhValidation(state.searchTxh.value);
        setState({...state, state})
    }
    return (
        <>
            <div className="SystemManage_CheckTxh_Page">
                <div className="card">
                    <div className="card-header">
                        <h4>
                            Control And Confirm Transaction Hash And Commissions.
                        </h4>
                    </div>
                    <div className="card-content">
                        <TextField
                            disabled={state.loading}
                            label="Transaction Hash Code (TxH)"
                            placeholder="Enter your Transaction Tx hash"
                            variant="outlined"
                            value={state.searchTxh.value}
                            onChange={(e) => {
                                state.searchTxh.value = e.target.value
                                setState({...state, state})
                            }}
                            onBlur={txhValidation}
                            error={state.searchTxh.hasError}
                            helperText={state.searchTxh.hasError && state.searchTxh.isDirty ? 'Check the value entered for "Transaction Hash Code (TxH)"!' : ' '}/>
                        <LoadingButton
                            variant="contained"
                            className="primary-btn"
                            loading={state.loading}
                            loadingPosition="center"
                            onClick={() => {
                                txhValidation();
                                if (
                                    !state.searchTxh.hasError
                                ) {
                                    state.loading = true;
                                    setState({...state, state})
                                    TransactionRepo.getByTxh(state.searchTxh.value).then((res: TransactionGetModel) => {
                                        navigator(Routes.systemManager.confirmTxh(state.searchTxh.value))
                                        state.loading = false;
                                        setState({...state, state})
                                    }).catch((err) => {
                                        ToastHelper.showError();
                                        state.loading = false;
                                        setState(state)
                                    })
                                }
                            }}>
                            Submit
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </>
    );
}

