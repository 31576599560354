export class AdminModel {
    adminId = 0;
    badge = '';
    email = '';
    firstName = '';
    fullName = '';
    gender = '';
    label = '';
    lastName = '';
    level = '';
    username = '';
    uuid = '';
    financialBalance = 0;
    editLock = false;
    isActive = false;
    forceUpdate = false;
    currentPackageId = 0;
}
